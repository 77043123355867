import { createSiteGuidedSchema } from './schemas/create_site_guided_schema';

app.NewSiteGuidedDetailsView = app.BaseDetailsView.extend({
  initialize(options) {
    options.model = new this.collection.model(
      {
        orgId: app.org.id,
      },
      {
        collection: options.collection,
        useShortTimezoneList: true,
      },
    );

    app.BaseDetailsView.prototype.initialize.apply(this, [options]);

    this.model.useShortTimezoneList = true;
  },

  getSchema() {
    return createSiteGuidedSchema;
  },

  _didSaveModel() {
    app.showSavedToast('Created a New Site!');
    setTimeout(() => {
      window.location.href = `${this.model.bureauUrl()}?create-location-step-by-step=true`;
    }, 1500);
  },
});
