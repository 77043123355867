import { createUsersGuidedSchema } from './schemas/create_users_guided_schema';

app.NewUsersGuidedDetailsView = app.BaseDetailsView.extend({
  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    if (app.userRoleList && !app.userRoleList.hasBeenFetched()) {
      this.listenToOnce(app.userRoleList, 'reset', this.render);
      app.userRoleList.fetch({ reset: true });
    }
  },

  getSchema() {
    return createUsersGuidedSchema;
  },

  _save(data, opts, $saveButton) {
    const self = this;
    const reqId = $saveButton.initLoadingButton(
      $saveButton.html(),
      this._saveButtonTitles()[1],
      this._saveButtonTitles()[2],
    );

    opts.error = () => {
      $saveButton.loadingDidFinishWithError(reqId);
    };
    opts.success = (_model, response) => {
      $saveButton.loadingDidFinishSuccessfully(reqId);

      response.data.users.forEach((user) => {
        self.collection.add(new app.User(user));
      });

      // Reset the form to its initial state so that the session user can create more users
      this.fieldGroupView.setValue(
        {
          type: this.model.attributes.type,
          orgId: this.model.attributes.orgId,
          locationGroupId: this.model.attributes.locationGroupId,
        },
        this.model,
      );
    };

    this.model.save(data, opts);
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    if (!this.$continueButton) {
      this.$continueButton = this.superView.footer.addButton('Next Step', 'next-step');
      this.$continueButton.click(() => {
        this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
      });
    }

    return this;
  },
});
