import Backbone from 'backbone';

import { template } from '../template';

app.IFrameView = Backbone.View.extend({
  template: template('<iframe src="<@= url @>" />'),
  className: 'iframe-view',

  initialize(options) {
    this.url = options.url;
  },

  render() {
    this.$el.html(this.template({ url: this.url }));

    return this;
  },
});
