app.OrgToolsView = app.BaseToolsView.extend({
  _deleteOrgButtonWasClicked(e) {
    if (!window.confirm('Are you sure you want to delete this org?')) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Deleting', 'Deleted');
    app.org.destroy({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success(data) {
        $button.loadingDidFinishSuccessfully(reqId);
        const message = data.status || 'Deleted this org!';
        app.showSavedToastAndReload(message);
        setTimeout(() => {
          window.location = '/';
        }, 1500);
      },
    });
  },

  _invalidateMenuCachesWasClicked(e) {
    if (
      !window.confirm(
        'Are you sure you want to invalidate the menu caches of all channels in the org?',
      )
    ) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Invalidating', 'Invalidated');
    app.org.refreshMenuCaches({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success() {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToastAndReload('Caches are clear!', true);
      },
    });
  },

  _removeArchiveButtonWasClicked(e) {
    const confirmed = app.promptUserToTypeInConfirmation(
      `Please type in "Delete the Archive" to confirm that you want to delete ALL archived menu items, sections, mods, and mod groups ACROSS ALL LOCATIONS in this org.`,
      'Delete the Archive',
    );
    if (!confirmed) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Removing', 'Removed');
    app.org.removeArchive({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success() {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToastAndReload('Removed menu archive!', true);
      },
    });
  },

  render() {
    app.BaseToolsView.prototype.render.apply(this, arguments);

    this.addToolbarButton(
      'btn-danger',
      'Invalidate Menu Caches',
      this._invalidateMenuCachesWasClicked.bind(this),
    );

    if (app.sessionUser.isBiteOps()) {
      this.addToolbarButton(
        'btn-danger',
        '🗑 Remove Archived Menu Things',
        this._removeArchiveButtonWasClicked.bind(this),
      );

      this.addToolbarButton(
        'btn-danger',
        'Delete This Org',
        this._deleteOrgButtonWasClicked.bind(this),
      );
    }

    return this;
  },
});
