import { ModelType, QuizMatchingType } from '@biteinc/enums';
import { quizSchema } from '@biteinc/schemas';

app.Quiz = app.AbstractModel.extend({
  ModelName: 'quiz',
  Schema: quizSchema,
  Type: ModelType.Quiz,

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;
    switch (fieldName) {
      case 'matchingAlgorithmType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: QuizMatchingType,
          nameGenerator: (matchingAlgorithmType) => {
            switch (matchingAlgorithmType) {
              case QuizMatchingType.Intersection:
                return 'Intersection';
              case QuizMatchingType.Union:
                return 'Union';
              default:
                throw new Error(
                  `unrecognized matchingAlgorithmType value: ${matchingAlgorithmType}`,
                );
            }
          },
        });
      case 'stepIds':
        return app.quizStepList;
    }
    return null;
  },
});
