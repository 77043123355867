import { LocationState } from '@biteinc/enums';

import { buildOrderChannelTag } from '../../helpers/html_builder';

app.SiteListView = app.BaseListView.extend({
  initialize(options) {
    options.cellOptions = {
      getNameUrl: (site) => {
        const nameInnerHtml = app.HtmlHelper.siteNameWithIntegrationsHtml(site);
        const channelTagHtml = app.HtmlHelper.getSiteLocations(site).reduce((html, location) => {
          const channelTag = buildOrderChannelTag(location.get('orderChannel'), {
            isLiveLocation: location.isLive(),
            isCanaryLocation: location.isCanary(),
          });
          return `${html}&nbsp;${channelTag}`;
        }, '&nbsp;');

        return {
          href: site.bureauUrl(),
          alt: site.get('name'),
          innerHtml: `${nameInnerHtml}${channelTagHtml}`,
        };
      },
      getSubtitle: (site) => {
        // If we have location groups add them together and display at the end of the name
        const locationGroupNames = app.HtmlHelper.getSiteLocationGroupsNames(
          site,
          app.locationGroupList,
        );
        const parts = [];
        if (site.get('kioskCount')) {
          parts.push(`Kiosks: ${site.get('kioskCount')}`);
        }
        if (locationGroupNames.length) {
          parts.push(`Groups: ${locationGroupNames.join(', ')}`);
        }
        const referenceLocations = app.locationList.hasBeenFetched()
          ? app.HtmlHelper.getSiteLocations(site).reduce((acc, location) => {
              // The session user might not have rights to access the reference location
              const menuReferenceLocation =
                location.get('menuReferenceLocationId') &&
                app.locationList.get(location.get('menuReferenceLocationId'));
              return menuReferenceLocation ? [...acc, menuReferenceLocation] : acc;
            }, [])
          : [];
        if (referenceLocations.length) {
          const referenceLocationNames = referenceLocations.map((location) => location.get('name'));
          parts.push(`Reference: ${referenceLocationNames.join(', ')}`);
        }
        return parts.join('<br />');
      },
    };

    this.listenTo(app.locationList, 'reset', (/* e */) => {
      if (this.$el.html().length) {
        this.render();
      }
    });

    app.BaseListView.prototype.initialize.apply(this, arguments);
  },

  getListSections() {
    const biteOnlySites = [];
    const nonBiteSites = [];
    this.collection.models.forEach((site) => {
      if (site.get('biteOnly')) {
        biteOnlySites.push(site);
        return;
      }
      nonBiteSites.push(site);
    });
    const liveLocations = app.locationList.models.filter((location) => {
      return location.get('state') === LocationState.Live;
    });
    const liveSiteSet = new Set();
    liveLocations.forEach((location) => {
      liveSiteSet.add(location.get('siteId'));
    });
    const liveLocationCount = liveLocations.length;
    const liveSiteCount = liveSiteSet.size;
    const liveSuffix =
      liveLocationCount > 0
        ? ` (${liveLocationCount} Live Channel${
            liveLocationCount === 1 ? '' : 's'
          } @ ${liveSiteCount} site${liveSiteCount === 1 ? '' : 's'})`
        : '';
    return [
      {
        header: `Client Sites${liveSuffix}`,
        models: nonBiteSites,
      },
      {
        header: 'Bite-Only Sites',
        models: biteOnlySites,
      },
    ];
  },

  _createSiteManual() {
    const model = new this.collection.model(
      {
        orgId: app.org.id,
      },
      {
        collection: this.collection,
      },
    );
    this.editModel(model);
  },

  _createSiteGuided() {
    const detailsView = new app.NewSiteGuidedDetailsView({
      collection: this.collection,
    });
    this.showDetailsView(detailsView);
  },

  render() {
    app.PaginatedListView.prototype.render.apply(this, arguments);

    if (!app.locationGroupList.hasBeenFetched()) {
      this.listenToOnce(app.locationGroupList, 'reset', this.render);
    }

    if (app.sessionUser.isBite()) {
      // Only Bite users can create sites using the guided flow because they need to be able to
      // choose a reference location.

      const collection = app.AbstractCollection.createFromArray({
        values: ['guided', 'manual'],
        nameGenerator: (value) => {
          switch (value) {
            case 'guided':
              return 'Guided';
            case 'manual':
              return 'Manual';
          }
        },
      });
      const dropdownView = new app.DropdownView({
        isPrimary: true,
      });
      this.$('.card-header .right-button-container').html(dropdownView.render().$el);
      dropdownView.setTitle(
        `<i class="bi bi-plus" aria-hidden="true"></i>&nbsp;new site ${app.HtmlHelper.resellerRightIcon}`,
      );
      dropdownView.setup(collection, {
        onAdd: (createFlow) => {
          switch (createFlow.id) {
            case 'manual':
              this._createSiteManual();
              break;
            case 'guided':
              this._createSiteGuided();
              break;
          }
        },
      });
    } else {
      const createButton = this.$('.card-header .right-button-container button.create');
      createButton.html(`${createButton.html()}${app.HtmlHelper.resellerRightIcon}`);
    }

    return this;
  },
});
