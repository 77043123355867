import { BiteUrl } from '@biteinc/common';
import { ModelType } from '@biteinc/enums';
import { quizStepSchema } from '@biteinc/schemas';

app.QuizStep = app.AbstractModel.extend({
  ModelName: 'quizStep',
  Schema: quizStepSchema,
  Type: ModelType.QuizStep,

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;

    if (fieldName === 'badgeIds') {
      return app.badgeList;
    }
    return null;
  },

  displayNameHtml() {
    let title = `<span>${this.displayName()}`;

    if (app.badgeList) {
      (this.get('badgeIds') || []).forEach((badgeId) => {
        const badge = app.badgeList.get(badgeId);
        if (badge) {
          const url = BiteUrl.biteAssetUrlWithNewHost(badge.get('icons')[0].url);
          const name = badge.get('name');
          title += `&nbsp;&nbsp;<img class="badge-img" src="${url}" title="${name}" />`;
        }
      });
    }

    title += '</span>';

    return title;
  },
});
