import type { Schema } from '@biteinc/common';
import { Timezone, UserRight } from '@biteinc/enums';

export const createSiteGuidedSchema: Schema.Model = {
  displayName: 'site',
  type: 'object',
  fields: {
    name: {
      type: 'keyString',
      required: true,
      writeRight: UserRight.CreateSitesAndChannels,
    },
    timezone: {
      displayName: 'Time zone',
      type: 'stringEnum',
      enum: Timezone,
      required: true,
      ui: 'radiolist',
      writeRight: UserRight.CreateSitesAndChannels,
      tooltip: {
        text: 'Only select timezones are displayed. If you do not see the desired timezone, choose the one most appropriate and edit the site later for more options.',
      },
    },
    biteOnly: {
      type: 'bool',
      displayName: 'Bite-Only',
      biteOnly: true,
    },
    fulfillmentStations: {
      type: 'array',
      elementType: 'object',
      ui: 'accordion',
      uiSubtype: 'FulfillmentStations',
      displayName: 'Fulfillment stations',
      tooltip: {
        text: 'Only applies to Fresh KDS.',
      },
      fields: {
        _id: {
          type: 'mongoId',
          isHidden: true,
        },
        name: {
          type: 'shortString',
          displayName: 'Name',
          required: true,
        },
        isEnabled: {
          type: 'bool',
          displayName: 'Enabled',
          defaultValue: true,
        },
      },
    },
  },
};
