import _ from 'underscore';

import { StringHelper } from '@biteinc/helpers';
import { menuCoverSchema } from '@biteinc/schemas';

const { fields: BlockFields } = menuCoverSchema.fields.portraitBlocks.fields.pathways;

app.MenuCoverPathwayBlockView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {};
      },
      getTitle: (fieldGroupView) => {
        const { linkType } = fieldGroupView.getValue();

        if (!linkType) {
          return '';
        }

        return StringHelper.toTitleCase(linkType.split('_').join(' '));
      },
      getElementSchemaFields: (/* element */) => {
        return BlockFields;
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new pathway block',
        emptyText: 'no pathway blocks yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
