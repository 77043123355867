import _ from 'underscore';

import { ThirdPartyOrderProvider, ThirdPartyOrderProviderHelper } from '@biteinc/enums';

app.ThirdPartyOrderingView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} third-party-ordering-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newElementDropdown: {
        getCollection: () => {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: ThirdPartyOrderProvider,
            nameGenerator: ThirdPartyOrderProviderHelper.name,
          });
        },
        getNewElementFromModel: (providerModel) => {
          const provider = providerModel.id;
          return {
            provider,
            notificationsEnabled: false,
          };
        },
        canShowModel: (providerModel, elements) => {
          // Filter out the fulfillment methods that are already entered (but maybe not saved)
          return !_.findWhere(elements, { provider: providerModel.id });
        },
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        let { provider } = fieldGroupView.getValue();

        return ThirdPartyOrderProviderHelper.name(provider);
      },
      templateData: {
        title: 'Third Party Ordering',
        buttonText: 'Configure New Provider',
        emptyText: 'No providers configured yet',
      },
      useElementAsKeyModel: true,
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
