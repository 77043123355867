import async from 'async';
import { Modal } from 'bootstrap';

import { IntegrationHelper } from '@biteinc/business-rules';
import { Log } from '@biteinc/common';
import type { I9nSchema } from '@biteinc/common';
import { OrderChannelHelper } from '@biteinc/enums';
import { Time } from '@biteinc/helpers';

import type { AbstractCollection } from '../types/abstract_collection';
import type { Integration } from '../types/integration';
import type { IntegrationData } from '../types/integration_data';
import type { KioskData } from '../types/kiosk_data';
import type { AlertView } from '../types/views/alert_view';
import { magicCopyOptionsSchema } from '../views/detail_views/schemas/magic_copy_options_schema';

/**
 * We need a timeout between modals because:
 * 1. Bootstrap will throw an error if we don't have a timeout between modals.
 *    I don't know why, but I don't think it's worth the effort to figure it out.
 * 2. If we open a new modal too soon, then the previous modal will still be in the stack and can
 *    come back when the current modal is closed.
 */
const MODAL_CLOSE_DELAY = 0.4 * Time.SECOND;

export function initializeLocationGuidedHelper(): void {
  async.auto(
    {
      createKiosks: (cb) => {
        promptUserToCreateKiosks(cb);
      },
      createUsers: [
        'createKiosks',
        (_results, cb) => {
          promptUserToCreateUsers(cb);
        },
      ],
      createI9ns: [
        'createUsers',
        (_results, cb) => {
          promptUserToCreateI9ns(cb);
        },
      ],
      firstSync: [
        'createI9ns',
        (results, cb) => {
          syncAllI9ns('Syncing integrations; this may take a few minutes', cb);
        },
      ],
      updateI9nsAfterFirstSync: [
        'firstSync',
        (results, cb) => {
          promptUserToUpdateI9ns(1, cb);
        },
      ],
      secondSync: [
        'updateI9nsAfterFirstSync',
        (results, cb) => {
          syncI9ns(
            results.updateI9nsAfterFirstSync,
            'Syncing integrations with updated data; this may take a few minutes',
            cb,
          );
        },
      ],
      updateI9nsAfterSecondSync: [
        'secondSync',
        (results, cb) => {
          promptUserToUpdateI9ns(2, cb);
        },
      ],
      thirdSync: [
        'updateI9nsAfterSecondSync',
        (results, cb) => {
          syncI9ns(
            results.updateI9nsAfterSecondSync,
            'Syncing integrations with updated data; this may take a few minutes',
            cb,
          );
        },
      ],
      magicCopy: [
        'thirdSync',
        (results, cb) => {
          // Run magic copy twice if we did sync some integration
          runMagicCopy(results.firstSync, cb);
        },
      ],
    },
    (err) => {
      if (err) {
        Log.error(err);
        new app.AlertView().show(`An error occurred while creating the location: ${err.message}`);
      } else {
        new app.AlertView().show(
          'Location creation complete! Close this pop-up to refresh the page.',
          null,
          () => {
            window.location.href = app.location.bureauUrl();
          },
        );
      }
    },
  );
}

function promptUserToCreateKiosks(callback: (error?: Error | null) => void): void {
  if (!OrderChannelHelper.isKiosk(app.location.get('orderChannel')!)) {
    // You can only create kiosks on a kiosk channel
    callback();
    return;
  }

  const referenceKioskList = new app.KioskList([], {
    menuReferenceLocationId: app.location.get('menuReferenceLocationId'),
  });
  referenceKioskList.once('reset', () => {
    const defaultKioskCreateValue = buildDefaultKioskCreateValue(referenceKioskList);

    let creatingKiosks = true;

    async.whilst(
      (testCb) => testCb(null, creatingKiosks),
      (fnCb) => {
        const model = new app.Kiosk({ ...defaultKioskCreateValue });
        const detailsView = new app.NewKioskGuidedDetailsView({
          collection: app.kioskList,
          model,
          hideDeleteButton: true,
        });

        const modalView = app.modalManager.showModalWithView(detailsView);
        modalView.footer.setCloseButtonVisible(false);

        detailsView.once(app.BaseDetailsView.Events.BaseDetailsViewDidClose, () => {
          creatingKiosks = detailsView.didClickNewModel;

          setTimeout(() => {
            fnCb();
          }, MODAL_CLOSE_DELAY);
        });
      },
      (err) => {
        callback(err);
      },
    );
  });
  referenceKioskList.fetch({ reset: true });
}

function buildDefaultKioskCreateValue(
  referenceKioskList: AbstractCollection<KioskData>,
): Record<string, string> {
  const createSchema = new app.NewKioskGuidedDetailsView({
    collection: app.kioskList,
  }).getSchema();

  const validKeysArray = Object.entries(createSchema.fields)
    .filter(([, field]) => !field.isHidden)
    .map(([fieldName]) => fieldName);
  const validKeysSet = new Set(validKeysArray);

  const timesEachValueIsUsedForEachKey = new Map<string, Map<any, number>>();
  referenceKioskList.forEach((referenceKiosk) => {
    Object.entries(referenceKiosk.attributes).forEach(([key, value]) => {
      if (!validKeysSet.has(key)) {
        return;
      }

      if (!timesEachValueIsUsedForEachKey.has(key)) {
        timesEachValueIsUsedForEachKey.set(key, new Map());
      }

      const timesEachValueIsUsed = timesEachValueIsUsedForEachKey.get(key)!;
      if (!timesEachValueIsUsed.has(value)) {
        timesEachValueIsUsed.set(value, 0);
      }

      timesEachValueIsUsed.set(value, timesEachValueIsUsed.get(value)! + 1);
    });
  });

  const mostCommonValueOfEachKey: Record<string, string> = {};
  timesEachValueIsUsedForEachKey.forEach((countValuesOfKey, key) => {
    let mostCommonValue: string | null = null;
    let mostCommonValueCount = 0;
    countValuesOfKey.forEach((count, value) => {
      if (count > mostCommonValueCount) {
        mostCommonValue = value;
        mostCommonValueCount = count;
      }
    });

    if (mostCommonValue) {
      mostCommonValueOfEachKey[key] = mostCommonValue;
    }
  });

  return mostCommonValueOfEachKey;
}

function promptUserToCreateUsers(callback: () => void): void {
  const detailsView = new app.NewUsersGuidedDetailsView({
    collection: app.userList,
  });

  const modalView = app.modalManager.showModalWithView(detailsView);
  modalView.footer.setCloseButtonVisible(false);

  detailsView.once(app.BaseDetailsView.Events.BaseDetailsViewDidClose, () => {
    setTimeout(() => {
      callback();
    }, MODAL_CLOSE_DELAY);
  });
}

function promptUserToCreateI9ns(callback: (err: Error | null | undefined) => void): void {
  async.forEachSeries(
    app.referenceIntegrationList.models,
    (referenceIntegration, next) => {
      const placeholderIntegration = buildPlaceholderI9n(referenceIntegration);
      const schema = buildI9nCreateSchema(placeholderIntegration);
      placeholderIntegration.setI9nSchema(schema);

      if (!doesSchemaHaveNonHiddenField(schema)) {
        // No user input needed

        placeholderIntegration.save(
          {},
          {
            success: () => {
              app.integrationList.add(placeholderIntegration);
              next();
            },
            error: (err) => {
              next(err);
            },
          },
        );

        return;
      }

      const detailsView = new app.IntegrationDetailsView({
        collection: app.integrationList,
        model: placeholderIntegration,
        preventPageReloadOnSave: true,
        hideDeleteButton: true,
        hideGenericFields: true,
        hideSyncInfoAndData: true,
      });

      const modalView = app.modalManager.showModalWithView(detailsView);
      modalView.footer.setCloseButtonVisible(false);

      const skipButton = modalView.footer.addButton('Skip', 'skip');
      skipButton.click(() => {
        app.modalManager.hideModal(modalView);
      });

      app.integrationList.once('add', () => {
        // When the integration is added to the collection, then it has been saved.
        app.modalManager.hideModal(modalView);
      });

      detailsView.once(app.BaseDetailsView.Events.BaseDetailsViewDidClose, () => {
        setTimeout(() => {
          next();
        }, MODAL_CLOSE_DELAY);
      });
    },
    (err) => {
      callback(err);
    },
  );
}

function buildPlaceholderI9n(referenceIntegration: Integration): Integration {
  const system = referenceIntegration.get('system')!;
  const copyableFieldNames = IntegrationHelper.getCopyableFieldNamesFromReferenceIntegration(
    system,
    0,
  ) as (keyof IntegrationData)[];
  const initialIntegrationData: Partial<IntegrationData> = copyableFieldNames.reduce(
    (acc, fieldName) => {
      if (!referenceIntegration.has(fieldName)) {
        return acc;
      }

      return {
        ...acc,
        [fieldName]: referenceIntegration.get(fieldName),
      };
    },
    { system },
  );

  if (!copyableFieldNames.includes('diningOptions') && referenceIntegration.get('diningOptions')) {
    // We can't copy all of the data in the dining options, but there may be a few fields we can

    const copyableDiningOptionFieldNames =
      IntegrationHelper.getCopyableDiningOptionFieldNamesFromReferenceIntegration(system, 0);
    const copyableDiningOptionFieldNamesSet = new Set(copyableDiningOptionFieldNames);

    const initialDiningOptionData = referenceIntegration
      .get('diningOptions')!
      // Only copy dining options that have at least one field we can copy
      .filter((referenceDiningOption) =>
        Object.keys(referenceDiningOption).some((fieldName) =>
          copyableDiningOptionFieldNamesSet.has(fieldName),
        ),
      )
      .map((referenceDiningOption) =>
        Object.entries(referenceDiningOption).reduce(
          (acc, [fieldName, value]) => {
            if (copyableDiningOptionFieldNamesSet.has(fieldName)) {
              // @ts-expect-error
              acc[fieldName] = value;
            }

            return acc;
          },
          {
            fulfillmentMethod: referenceDiningOption.fulfillmentMethod,
          } as typeof referenceDiningOption,
        ),
      );

    if (initialDiningOptionData.length) {
      initialIntegrationData.diningOptions = initialDiningOptionData;
    }
  }

  const placeholderIntegration = new app.Integration(initialIntegrationData, {
    collection: app.integrationList,
  });
  placeholderIntegration.setIsPlaceholder();

  return placeholderIntegration;
}

function buildI9nCreateSchema(placeholderIntegration: Integration): I9nSchema {
  const system = placeholderIntegration.get('system')!;
  const copyableFieldNames = IntegrationHelper.getCopyableFieldNamesFromReferenceIntegration(
    system,
    0,
  );

  const subSchema = app.JsonHelper.deepClone(placeholderIntegration.getI9nSchema());
  copyableFieldNames.forEach((fieldName) => {
    if (subSchema.fields[fieldName]) {
      // @ts-expect-error
      subSchema.fields[fieldName].isHidden = true;
    }
  });

  // Hide fields that the user cannot set yet.
  Object.values(subSchema.fields ?? {}).forEach((field) => {
    if (field.isSetAfterFirstSync || field.isSetAfterSecondSync) {
      field.isHidden = true;
    }
  });
  Object.values(('diningOptionFields' in subSchema && subSchema.diningOptionFields) ?? {}).forEach(
    (field) => {
      if (field.isSetAfterFirstSync || field.isSetAfterSecondSync) {
        field.isHidden = true;
      }
    },
  );

  return subSchema;
}

function syncAllI9ns(
  waitingMessage: string | null,
  callback: (err: Error | null | undefined, didSyncSomeI9n: boolean) => void,
): void {
  syncI9ns(app.integrationList, waitingMessage, callback);
}

function syncI9ns(
  i9nCollection: AbstractCollection<IntegrationData, Integration>,
  waitingMessage: string | null,
  callback: (err: Error | null | undefined, didSyncSomeI9n: boolean) => void,
): void {
  let hasFoundPosI9n = false;

  const syncableI9ns = i9nCollection.models.filter((i9n) => i9n.canBeSynced());
  if (!syncableI9ns.length) {
    callback(null, false);
    return;
  }

  let alertModal: AlertView | null = null;
  if (waitingMessage) {
    alertModal = new app.AlertView();
    alertModal.show(waitingMessage, null, null, null, null, { hideConfirmButton: true });
  }

  async.forEachSeries(
    syncableI9ns,
    (i9n, next) => {
      if (i9n.getI9nSchema().type === 'pos') {
        if (hasFoundPosI9n) {
          // Syncing one POS will sync them all
          next();
          return;
        }

        hasFoundPosI9n = true;
      }

      i9n.syncI9n(next);
    },
    (err) => {
      function closeModalAndCallback(): void {
        if (alertModal) {
          Modal.getOrCreateInstance(alertModal.el).hide();
          setTimeout(() => {
            callback(err, !err);
          }, MODAL_CLOSE_DELAY);
        } else {
          callback(err, !err);
        }
      }

      if (err) {
        closeModalAndCallback();
        return;
      }

      // Reset the integration list to get the synced data
      app.integrationList.once('reset', () => {
        closeModalAndCallback();
      });
      app.integrationList.fetch({ reset: true });
    },
  );
}

function doesSchemaHaveNonHiddenField(schema: I9nSchema): boolean {
  const hasNonHiddenField = Object.values(schema.fields ?? {}).some((field) => !field.isHidden);
  const hasNonHiddenDiningOptionField = Object.values(
    ('diningOptionFields' in schema && schema.diningOptionFields) ?? {},
  ).some((diningOptionField) => !diningOptionField.isHidden);

  return hasNonHiddenField || hasNonHiddenDiningOptionField;
}

function promptUserToUpdateI9ns(
  timesSynced: number,
  callback: (
    err: Error | null | undefined,
    updatedI9ns?: AbstractCollection<IntegrationData, Integration>,
  ) => void,
): void {
  const updatedI9ns = new app.IntegrationList();

  async.forEachSeries(
    app.integrationList.models,
    (i9n, next) => {
      const schema = buildI9nUpdateSchema(i9n, timesSynced);
      const defaultValue = buildDefaultI9nUpdateValue(i9n, schema, timesSynced);

      if (!doesSchemaHaveNonHiddenField(schema)) {
        // No user input needed

        if (!Object.keys(defaultValue).length) {
          // No data to update
          next();
          return;
        }

        i9n.save(defaultValue, {
          success: () => {
            next();
          },
          error: (err) => {
            next(err);
          },
        });

        return;
      }

      i9n.setI9nSchema(schema);

      const detailsView = new app.IntegrationDetailsView({
        collection: app.integrationList,
        model: i9n,
        preventPageReloadOnSave: true,
        hideGenericFields: true,
        hideDeleteButton: true,
        hideSyncInfoAndData: true,
      });

      const modalView = app.modalManager.showModalWithView(detailsView);
      modalView.footer.setCloseButtonVisible(false);

      detailsView.fieldGroupView.setValue(defaultValue, i9n);

      const continueButton = modalView.footer.addButton('Continue', 'continue');
      continueButton.click(() => {
        app.modalManager.hideModal(modalView);
      });

      // Track integrations that have been updated so that we know which ones to sync.
      i9n.once('change', () => {
        updatedI9ns.add(i9n);
      });

      detailsView.once(app.BaseDetailsView.Events.BaseDetailsViewDidClose, () => {
        setTimeout(() => {
          next();
        }, MODAL_CLOSE_DELAY);
      });
    },
    (err) => {
      if (err) {
        callback(err);
      } else {
        callback(null, updatedI9ns);
      }
    },
  );
}

function buildI9nUpdateSchema(i9n: Integration, timesSynced: number): I9nSchema {
  const system = i9n.get('system')!;
  const subSchema = app.JsonHelper.deepClone(i9n.getI9nSchema());

  const isSetAfterNthSync = timesSynced === 1 ? 'isSetAfterFirstSync' : 'isSetAfterSecondSync';

  const copyableFieldNames = IntegrationHelper.getCopyableFieldNamesFromReferenceIntegration(
    system,
    timesSynced,
  );

  const copyableDiningOptionFieldNames =
    IntegrationHelper.getCopyableDiningOptionFieldNamesFromReferenceIntegration(
      system,
      timesSynced,
    );

  function hideFieldsInSubSchema(
    fieldsKey: keyof I9nSchema,
    copyableFieldNamesSet: Set<string>,
  ): void {
    if (!subSchema[fieldsKey]) {
      return;
    }

    // Replace the fields such that only the fields that are set after the nth sync are kept
    Object.entries(subSchema[fieldsKey]).forEach(([fieldName, field]) => {
      // Only show fields relevant to the current sync and won't be copied from the reference
      if (!field[isSetAfterNthSync] || copyableFieldNamesSet.has(fieldName)) {
        field.isHidden = true;
      }
    });
  }

  hideFieldsInSubSchema('fields', new Set(copyableFieldNames));
  hideFieldsInSubSchema(
    'diningOptionFields' as keyof I9nSchema,
    new Set(copyableDiningOptionFieldNames),
  );

  return subSchema;
}

function buildDefaultI9nUpdateValue(
  i9n: Integration,
  schema: I9nSchema,
  timesSynced: number,
): Partial<IntegrationData> {
  const system = i9n.get('system')!;

  const updateValue: Partial<IntegrationData> = {};

  const copyableFieldNames = IntegrationHelper.getCopyableFieldNamesFromReferenceIntegration(
    system,
    timesSynced,
  ) as (keyof IntegrationData)[];

  // A multi-vendor location cannot be a reference location, so there should only be at most one
  // reference integration per system.
  const referenceIntegration = app.referenceIntegrationList.models.find((referenceI9n) => {
    return referenceI9n.getI9nSchema().system === schema.system;
  })!;
  copyableFieldNames.forEach((fieldName) => {
    // @ts-expect-error
    updateValue[fieldName] = referenceIntegration.get(fieldName);
  });

  // If we're already copying the dining options whole sale, then we don't have to go through them
  // and pick the individual copyable fields; they're all being copied.
  if (
    'diningOptionFields' in schema &&
    schema.diningOptionFields &&
    !copyableFieldNames.includes('diningOptions')
  ) {
    const copyableDiningOptionFieldNames =
      IntegrationHelper.getCopyableDiningOptionFieldNamesFromReferenceIntegration(
        system,
        timesSynced,
      );
    const copyableDiningOptionFieldNamesSet = new Set(copyableDiningOptionFieldNames);

    const referenceDiningOptions = referenceIntegration.get('diningOptions') ?? [];

    const referenceIntegrationHasSomeDiningOptionField = referenceDiningOptions.some(
      (referenceDiningOption) => {
        return Object.keys(referenceDiningOption).some((diningOptionFieldName) =>
          copyableDiningOptionFieldNamesSet.has(diningOptionFieldName),
        );
      },
    );

    if (referenceIntegrationHasSomeDiningOptionField) {
      // We know that there must be some dining option we can update, so it's safe to initialize
      // an empty array.
      updateValue.diningOptions = i9n.get('diningOptions') ?? [];

      referenceDiningOptions.forEach((referenceDiningOption) => {
        const referenceDiningOptionHasSomeField = Object.keys(referenceDiningOption).some(
          (diningOptionFieldName) => copyableDiningOptionFieldNamesSet.has(diningOptionFieldName),
        );
        if (!referenceDiningOptionHasSomeField) {
          // This reference dining option doesn't have any fields that we can copy, so skip it
          return;
        }

        // Use the fulfillment method as a key for the dining options and find the existing one.
        const existingDiningOption = updateValue.diningOptions!.find(
          (diningOption) =>
            diningOption.fulfillmentMethod === referenceDiningOption.fulfillmentMethod,
        );

        let phiDiningOption = existingDiningOption;
        if (!existingDiningOption) {
          // If the existing dining option doesn't exist, then create a new one.
          // We can add it to the array because we know that the reference integration has some
          // dining option field.
          phiDiningOption = { fulfillmentMethod: referenceDiningOption.fulfillmentMethod };
          updateValue.diningOptions!.push(phiDiningOption);
        }

        Object.keys(referenceDiningOption).forEach((diningOptionFieldName) => {
          if (copyableDiningOptionFieldNamesSet.has(diningOptionFieldName)) {
            // @ts-expect-error
            phiDiningOption[diningOptionFieldName] = referenceDiningOption[diningOptionFieldName];
          }
        });
      });
    }
  }

  return updateValue;
}

function runMagicCopy(runTwice: boolean, callback: (err: Error | null | undefined) => void): void {
  const url = '/api/v2/magic-copy/location';

  // Select all options including the ones with warnings attached.
  // However don't copy price and taxes if it is not applicable.
  const options = Object.keys(magicCopyOptionsSchema.fields).reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]: true,
      };
    },
    {} as Record<string, boolean>,
  );

  // The location model is not updated at this point, so we need to check the integration list.
  // Ideally we could fetch and update the location model, but I couldn't get it to work.
  const locationHasPos = app.integrationList.some((i9n) => i9n.isPosI9n());
  if (locationHasPos) {
    delete options.pricesAndTaxes;
  }

  let onCreateModal: AlertView | null = null;

  async.auto(
    {
      firstMagicCopy: (firstMagicCopyCb) => {
        let encounteredErrorDuringCreate = false;

        app.QueueHelper.makeRequest(
          'POST',
          url,
          {
            fromLocationId: app.location.get('menuReferenceLocationId'),
            options,
          },
          false,
          (err) => {
            if (encounteredErrorDuringCreate) {
              // If an error occurred during create, then don't call the callback again
              return;
            }

            // The errors that we get back are just messages; wrap them in an error object
            firstMagicCopyCb(err ? new Error(err) : null);
          },
          (err, createData) => {
            if (err) {
              encounteredErrorDuringCreate = true;
              firstMagicCopyCb(new Error(err));
              return;
            }

            const fullTimeout = runTwice ? 2 * createData!.timeout : createData!.timeout;
            const timeoutStr = `${Math.ceil(fullTimeout / Time.MINUTE)}`;

            onCreateModal = new app.AlertView();
            onCreateModal.show(
              `Data is being copied from the reference location. Estimated time: ${timeoutStr} minutes`,
              null,
              null,
              null,
              null,
              { hideConfirmButton: true },
            );
          },
        );
      },
      sync: [
        'firstMagicCopy',
        (magicCopyResults, syncCb) => {
          syncAllI9ns(null, syncCb);
        },
      ],
      secondMagicCopy: [
        'sync',
        (_magicCopyResults, secondMagicCopyCb) => {
          function closeModalAndCallback(err?: string): void {
            if (onCreateModal) {
              Modal.getOrCreateInstance(onCreateModal.el).hide();
              setTimeout(() => {
                secondMagicCopyCb(err ? new Error(err) : null);
              }, MODAL_CLOSE_DELAY);
            } else {
              secondMagicCopyCb(err ? new Error(err) : null);
            }
          }

          if (!runTwice) {
            closeModalAndCallback();
            return;
          }

          app.QueueHelper.makeRequest(
            'POST',
            url,
            {
              fromLocationId: app.location.get('menuReferenceLocationId'),
              options,
            },
            false,
            (err) => {
              closeModalAndCallback(err);
            },
          );
        },
      ],
    },
    (err) => {
      callback(err);
    },
  );
}
