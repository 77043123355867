import { settingsSchema } from '@biteinc/schemas';

import { createLocationGuidedHelper } from '../helpers/create_location_guided_helper';

app.SiteView = app.TabbedView.extend({
  requiresPageHeader: true,
  context: 'site',

  initialize() {
    app.TabbedView.prototype.initialize.apply(this, arguments);

    // CHANNELS TAB
    const locationListView = new app.LocationListView({
      collection: app.locationList,
      title: `${app.org.displayName()} Channels`,
      canCreate: app.sessionUser.canCreateSitesAndChannels(),
      searchEnabled: false,
    });

    const locationsTab = new app.TabView();
    locationsTab.setLeftView(locationListView);
    this.addTab(locationsTab, 'Channels', 'channels');

    // All site-level reports require the user to have view-orders permission
    const canAccessReporting =
      app.sessionUser.canGenerateReports() && app.sessionUser.canViewOrders();
    const canAccessActivityLog = app.sessionUser.canViewActivities();
    const canAccessSystemLogs = app.sessionUser.canViewSystemLogs();

    if (canAccessReporting || canAccessActivityLog || canAccessSystemLogs) {
      this.addSpacer();
      this.addSeparatorWithTitle('Ops');

      // REPORTING TAB
      if (canAccessReporting) {
        const oneTimeReportView = new app.OneTimeReportView({
          model: new app.OneTimeReport(),
          schema: app.OneTimeReport.Schema(),
          title: 'Run a One-Time Report',
          saveButtonTitles: ['Run', 'Running', 'Done!'],
        });

        app.reportScheduleList = new app.ReportScheduleList();
        const reportScheduleListView = new app.BaseListView({
          collection: app.reportScheduleList,
          detailsView: app.ReportScheduleDetailsView,
          searchEnabled: false,
          fetchOnInit: false,
        });
        const reportingTab = new app.TabView();
        reportingTab.setLeftView(oneTimeReportView, true);
        reportingTab.setRightView(reportScheduleListView);
        this.addTab(reportingTab, 'Reporting', 'reporting');
      }

      if (canAccessActivityLog) {
        // ACTIVITY LOGS TAB
        const activityTab = new app.TabView();
        app.activityList = new app.MaitredActivityList();
        const activityListView = new app.MaitredActivityListView({
          collection: app.activityList,
        });
        activityTab.setLeftView(activityListView);
        this.addTab(activityTab, 'Activity Log', 'activity-log');
      }

      if (canAccessSystemLogs) {
        // SYSTEM LOGS TAB
        app.biteLogList = new app.BiteLogList();
        const biteLogListView = new app.BiteLogListView({
          collection: app.biteLogList,
        });
        const logTab = new app.TabView();
        logTab.setLeftView(biteLogListView);
        this.addTab(logTab, `${app.HtmlHelper.resellerRightIcon} System Logs`, 'system-logs');
      }
    }

    const canAccessOrderThrottling = app.sessionUser.canManageOrderThrottling();
    const isBiteSupport = app.sessionUser.isBiteSupport();
    if (canAccessOrderThrottling || isBiteSupport) {
      this.addSpacer();
      this.addSeparatorWithTitle('Configuration');

      // ORDER THROTTLING TAB
      if (canAccessOrderThrottling) {
        this._addSubSchemaTab('Order Throttling', 'order-throttling');
      }

      if (isBiteSupport) {
        // SITE SETTINGS TAB
        const settingsTab = this._addSubSchemaTab(
          `${app.HtmlHelper.resellerRightIcon} Settings`,
          'site-settings',
        );
        settingsTab.setRightView(new app.SiteToolsView());
      }
    }

    const isBite = app.sessionUser.isBite();
    const canAccessCommonSettings = app.sessionUser.canAccessSomeCommonSettings();
    const canManageFlashSettings = app.sessionUser.canManageFlashSettings();
    const canManageChannelSettings = app.sessionUser.canManageChannelSettings();
    const canManageMenuAppearance = app.sessionUser.canEditAppearance();
    const canManageBiteLiftSettings = app.sessionUser.canManageBiteLift();
    if (
      isBite ||
      canAccessCommonSettings ||
      canManageFlashSettings ||
      canManageChannelSettings ||
      canManageMenuAppearance ||
      canManageBiteLiftSettings
    ) {
      // Configuration V2
      this.addSpacer();
      this.addSeparatorWithTitle('Configuration V2');
      // Settings V2
      if (app.sessionUser.isBite()) {
        this.addSettingsTab(
          app.siteSettings,
          settingsSchema,
          'Bite Settings',
          'bite-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (app.sessionUser.canAccessSomeCommonSettings()) {
        this.addSettingsTab(
          app.siteSettings,
          settingsSchema,
          'Common Settings',
          'common-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (app.sessionUser.canManageFlashSettings()) {
        this.addSettingsTab(
          app.siteSettings,
          settingsSchema,
          'Flash Settings',
          'flash-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (app.sessionUser.canManageChannelSettings()) {
        this.addSettingsTab(
          app.siteSettings,
          settingsSchema,
          'Kiosk Settings',
          'kiosk-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (app.sessionUser.canEditAppearance()) {
        this.addSettingsTab(
          app.siteSettings,
          settingsSchema,
          'Menu Appearance Settings',
          'menu-appearance-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (app.sessionUser.canManageBiteLift()) {
        this.addSettingsTab(
          app.siteSettings,
          settingsSchema,
          'Recommendations Settings',
          'recommendation-settings-v2',
          app.SettingsDetailsView,
        );
      }
    }

    this.addBottomLinks(true /* help */, true /* edit profile */);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('create-location-step-by-step')) {
      createLocationGuidedHelper();
    }
  },

  setPageHeader(header) {
    app.TabbedView.prototype.setPageHeader.apply(this, arguments);

    if (app.sessionUser.isBite() || app.sessionUser.getOrgMemberships().length === 1) {
      header.setMainTitle(app.org.get('name'), app.org.get('name'), app.org.bureauUrl());
    } else {
      header.addOrgDropdown();
    }
    header.addSiteDropdown(app.siteList.models, app.site.get('name'), true);
  },

  _addSubSchemaTab(tabName, tabId) {
    return this.addSettingsTab(app.site, app.Site.Schema(), tabName, tabId);
  },
});
