app.NewKioskGuidedDetailsView = app.KioskDetailsView.extend({
  render() {
    app.KioskDetailsView.prototype.render.apply(this);

    if (!this.$continueButton) {
      this.$continueButton = this.superView.footer.addButton('Next Step', 'next-step');
      this.$continueButton.click(() => {
        this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
      });
    }

    if (!this.$resetButton) {
      this.$resetButton = this.superView.footer.addButton('New Kiosk', 'new-kiosk');
      this.$resetButton.click(() => {
        // The user wants to make a new kiosk, so check if there are any unsaved changes.
        // If there are, then we'll display the discard panel on the footer asking for confirmation.
        // If the user confirms, then the modal will close.

        // Set a flag on the details view indicating that the modal closed because the user clicked
        // the new kiosk button.
        this.didClickNewModel = true;

        if (this.checkForUnsavedChanges()) {
          return;
        }

        // There are not, so we can close the modal right now.
        this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
      });
    }

    this.superView.footer.$discardPanel.find('.cancel').click(() => {
      // If the user clicked the cancel button, then we may not want to build a new model.
      this.didClickNewModel = false;
    });

    // Only show the restart button if the view is in edit mode.
    this.$resetButton.toggle(!this.model.isNew());

    return this;
  },
});
