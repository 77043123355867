import { ModelType, WebhookEvent, WebhookType } from '@biteinc/enums';

import { webhookSchema } from './webhook_schema';

app.Webhook = app.AbstractModel.extend({
  ModelName: 'webhook',
  Type: ModelType.Webhook,
  Schema: webhookSchema,

  displayName() {
    const locationGroup = app.locationGroupList.find((lgContender) => {
      return lgContender.id === this.get('locationGroupId');
    });
    return `${locationGroup.displayNameAsListFieldElement(true)} | ${this.get('url')}`;
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'locationGroupId':
        return app.LocationGroupList.newSelectableList(app.locationGroupList, {
          includeOrgWide: true,
          includeAllLive: true,
          includeSingleLocationGroups: true,
        });
      case 'type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: WebhookType,
          nameGenerator: (webhookType) => {
            switch (webhookType) {
              case WebhookType.FirstParty:
                return 'First Party';
              case WebhookType.ThirdParty:
                return 'Third Party';
            }
          },
        });
      case 'events':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: WebhookEvent,
          nameGenerator: (webhookEvent) => {
            switch (webhookEvent) {
              case WebhookEvent.OrderSent:
                return 'Order Sent';
              case WebhookEvent.OrderUpdated:
                return 'Order Updated';
              case WebhookEvent.ThirdPartyOrderSent:
                return 'Third Party Order Sent';
            }
          },
        });
    }
    return null;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    switch (field) {
      case 'locationGroupId': {
        const locationGroup = element;
        return locationGroup.displayNameAsListFieldElement(plainTextOnly);
      }
    }
    return displayName;
  },

  canBeDestroyed() {
    return true;
  },
});
