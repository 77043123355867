import Backbone from 'backbone';
import Sortable from 'sortablejs';
import _ from 'underscore';

import { MenuPageDesignation, UserRight } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';
import { menuPageSchema, menuStructureSchema } from '@biteinc/schemas';

import { template } from '../template';

app.MenuPageView = Backbone.View.extend({
  template: template($('#menu-page-template').html()),
  className: 'page-container card panel-default list-group',

  // Requires menu, page, level
  initialize(options) {
    /** @type {app.MenuStructure} */
    this.model = options.model;
    this.level = options.level;
    this.tabView = options.tabView;
    this.pageViews = [];
    this.pageViewsById = {};
    this.setPage(options.page);

    if (!this.level) {
      if (!app.menuCoverList.hasBeenFetched()) {
        app.menuCoverList.fetch({ reset: true });
      }
      this.listenToOnce(app.menuCoverList, 'reset', () => {
        if (this.menuCoverFieldView) {
          this.menuCoverFieldView.render();
          this.menuCoverFieldView.setValue(this.model.get('menuCoverId'), this.model);
        }
      });
      this.listenToOnce(app.menuTimingList, 'reset', () => {
        if (this.dayPartFieldView) {
          this.dayPartFieldView.render();
          this.dayPartFieldView.setValue(this.model.get('menuTimingId'), this.model);
        }
      });
    }
  },

  setPage(page, propagate) {
    this.page = page;
    this.pagesById = {};
    this.page.pages = this.page.pages || [];
    this.page.sectionIds = this.page.sectionIds || [];

    _.each(this.page.pages, (subPage) => {
      this.pagesById[subPage._id] = subPage;
    });

    if (propagate) {
      for (let i = 0; i < this.page.pages.length; i++) {
        const p = this.page.pages[i];
        const pageView = this.pageViewsById[p._id];
        if (pageView) {
          pageView.setPage(p, propagate);
        }
      }
    }
  },

  createPage(name, $button, $inputField) {
    const self = this;
    const reqId = $button.initLoadingButton('Add', 'Adding', 'Added');
    if ($inputField) {
      $inputField.val('');
    }
    const page = {
      _id: StringHelper.randomHexString(24),
      name,
      designation: MenuPageDesignation.Regular,
    };
    // Append associated vendor id to sub-pages
    if (self.page.associatedVendorId) {
      page.associatedVendorId = self.page.associatedVendorId;
    }
    self.page.pages.push(page);
    self._save({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success() {
        $button.loadingDidFinishSuccessfully(reqId);
        // Need to render, for associatedVendor field to show
        if (self.level === 1 && _.size(self.page.pages) === 1) {
          self.render();
        } else {
          for (let i = 0; i < self.page.pages.length; i++) {
            const p = self.page.pages[i];
            if (p._id === page._id) {
              self.pagesById[p._id] = p;
              self.addPage(p, /* animated */ true);
              return;
            }
          }
        }
      },
    });
  },

  setUpControlPane() {
    this.$allControlPanes.find('button.close').click(() => {
      this.showAppropriateControlPane();
    });
    if (app.sessionUser.canManageMenu()) {
      this.$controlPaneBoth.find('button.page').click(() => {
        this.$allControlPanes.hide();
        this.$controlPanePage.show();
      });
      this.$controlPaneBoth.find('button.section').click(() => {
        this.$allControlPanes.hide();
        this.$controlPaneSection.show();
      });
    } else {
      this.$controlPaneBoth.find('button.page').prop('disabled', true);
      this.$controlPaneBoth.find('button.section').prop('disabled', true);
    }

    if (!this.level) {
      this.dayPartFieldView = new app.DropdownFieldView({
        field: 'menuTimingId',
        schema: {
          type: 'mongoId',
          displayName: 'Day Part',
          ui: 'dropdown',
          writeRight: UserRight.ManageMenu,
        },
      });
      this.$menuTimingControl.html(this.dayPartFieldView.render().$el);
      this.dayPartFieldView.setValue(this.model.get('menuTimingId'), this.model);
      this.listenTo(this.dayPartFieldView, app.FieldView.Events.FieldDidChangeValue, () => {
        this.model.save(
          {
            menuTimingId: this.dayPartFieldView.getValue(),
          },
          {
            patch: true,
            error: () => {
              this.render();
            },
            success() {
              app.showSavedToast();
            },
          },
        );
      });

      this.menuCoverFieldView = new app.DropdownFieldView({
        field: 'menuCoverId',
        schema: {
          type: 'mongoId',
          displayName: 'Menu Cover',
          ui: 'dropdown',
          writeRight: UserRight.ManageMenu,
        },
      });
      this.$menuCoverControl.html(this.menuCoverFieldView.render().$el);
      this.menuCoverFieldView.setValue(this.model.get('menuCoverId'), this.model);
      this.listenTo(this.menuCoverFieldView, app.FieldView.Events.FieldDidChangeValue, () => {
        this.model.save(
          {
            menuCoverId: this.menuCoverFieldView.getValue(),
          },
          {
            patch: true,
            error: () => {
              this.render();
            },
            success() {
              app.showSavedToast();
            },
          },
        );
      });
      const fulfillmentMethodFieldView = new app.ListFieldView({
        field: 'fulfillmentMethods',
        schema: menuStructureSchema.fields.fulfillmentMethods,
      });
      this.$fulfillmentMethodControl.html(fulfillmentMethodFieldView.render().$el);
      fulfillmentMethodFieldView.setValue(this.model.get('fulfillmentMethods'), this.model);
      this.listenTo(fulfillmentMethodFieldView, app.FieldView.Events.FieldDidChangeValue, () => {
        this.model.save(
          {
            fulfillmentMethods: fulfillmentMethodFieldView.getValue(),
          },
          {
            patch: true,
            error: () => {
              this.render();
            },
            success() {
              app.showSavedToast();
            },
          },
        );
      });

      if (app.location.get('useVitrine') && app.quizList?.size()) {
        this.quizFieldView = new app.NestedObjectFieldView({
          schema: menuStructureSchema.fields.quiz,
          field: 'quiz',
        });

        this.$quizControl.html(this.quizFieldView.render().$el);

        this.quizFieldView.setValue(
          this.model.get('quiz') || {
            displayType: '',
            quizIds: [],
            headerScrimImage: [],
          },
          this.model,
        );

        this.listenTo(this.quizFieldView, app.FieldView.Events.FieldDidChangeValue, () => {
          this.model.save(
            {
              quiz: this.quizFieldView.getValue(),
            },
            {
              patch: true,
              error: () => {
                this.render();
              },
              success() {
                app.showSavedToast();
              },
            },
          );
        });
      }
    }

    app.attachNewFormListeners(this.$controlPanePage, (name, $button, $inputField) => {
      this.createPage(name, $button, $inputField, false, false);
    });
  },

  showAppropriateControlPane() {
    this.$allControlPanes.hide();

    if (this.level) {
      this.$menuCoverControl.hide();
      this.$quizControl.hide();
      this.$menuTimingControl.hide();
      this.$fulfillmentMethodControl.hide();
    }

    if (!app.location.get('useVitrine') || !app.quizList?.size()) {
      this.$quizControl.hide();
    }

    const pageCount = this.page.pages.length;
    let placeholder = `add ${pageCount ? 'another' : 'a new'}`;
    placeholder += ' menu ';
    placeholder += this.level ? `sub-page to ${this.page.name}` : 'page';
    this.$controlPanePage.find('input').attr('placeholder', placeholder);

    this.$controlPanePage
      .find('.new-panel input')
      .prop('disabled', !app.sessionUser.canManageMenu());
    this.$controlPanePage
      .find('.new-panel button')
      .prop('disabled', !app.sessionUser.canManageMenu());

    const title = `Menu ${this.level ? 'Sub-pages' : 'Pages'}`;
    this.$headingTitle.html(title);
    if (!this.page.pages.length && !this.page.sectionIds.length && !this.page.associatedVendorId) {
      if (this.level < 1) {
        this.$controlPaneSection.find('div.closer').hide();
        this.$controlPanePage.show();
      } else if (this.level < 2) {
        this.$allControlPanes.find('div.closer').show();
        this.$controlPaneBoth.show();
      } else {
        this.$controlPaneSection.find('div.closer').hide();
        this.$controlPaneSection.show();
      }

      if (this.level) {
        this.$heading.hide();
        this.$controlPaneBoth.find('span.to').html(`to ${this.page.name}`);
      }
    } else {
      this.$allControlPanes.find('div.closer').hide();

      if (this.page.pages.length) {
        this.$heading.show();
        if (this.level === 1) {
          // Page with subPages
          this.$controlPaneSubPage.show();
        }
        this.$controlPanePage.show();
        this.$headingTitle.html(title);
      } else {
        this.$controlPaneSection.show();
        this.$heading.hide();
      }
    }
  },

  addPage(page, animated) {
    this.renderPage(page, animated);
    this.showAppropriateControlPane();
  },

  _indexOfPageWithId(pageId) {
    for (let i = 0; i < this.page.pages.length; i++) {
      if (this.page.pages[i]._id === pageId) {
        return i;
      }
    }
    return -1;
  },

  movePage(oldIndex, newIndex) {
    const page = this.page.pages[oldIndex];
    this.page.pages.splice(oldIndex, 1);
    this.page.pages.splice(newIndex, 0, page);

    const self = this;
    this._save({
      success() {
        self.showAppropriateControlPane();
        window.app.showSavedToast();
      },
    });
  },

  removePage(page, onError, onSuccess) {
    const index = this._indexOfPageWithId(page._id);
    if (index < 0) {
      return;
    }

    this.page.pages.splice(index, 1);
    const self = this;
    this._save({
      error: onError,
      success() {
        delete self.pageViewsById[page._id];
        if (onSuccess) {
          onSuccess();
        }
        self.showAppropriateControlPane();
        window.app.showSavedToast();
      },
    });
  },

  renderPage(page, animated) {
    let pageView = null;

    const confirmation = 'Are you sure you want to delete this menu page?';

    const self = this;
    const cell = new app.ComplexCellView({
      confirmation,
      onClick() {
        pageView.$el.slideToggle(400, 'easeInOutCubic');
      },
      onDelete($button) {
        const title = 'Removing';
        const reqId = $button.initLoadingButton($button.text(), title);
        self.removePage(
          page,
          () => {
            $button.loadingDidFinishWithError(reqId);
          },
          () => {
            $button.loadingDidFinishSuccessfully(reqId);
            cell.remove();
          },
        );
      },
      onRename(name, callback, $button) {
        const reqId = $button.initLoadingButton($button.text(), 'Renaming');
        const p = self.pagesById[page._id];
        p.name = name;
        self._save({
          error() {
            $button.loadingDidFinishWithError(reqId);
          },
          success() {
            $button.loadingDidFinishSuccessfully(reqId);
            if (callback) {
              callback();
            }
            window.app.showSavedToast();
          },
        });
      },
      deleteButtonName: 'Delete',
      getName() {
        return self.pagesById[page._id].name;
      },
      canRename() {
        return app.sessionUser.canManageMenu();
      },
      canDelete() {
        return app.sessionUser.canManageMenu();
      },
      canReorder() {
        return app.sessionUser.canManageMenu();
      },
    });
    this.$pageList.append(cell.render().el);

    pageView = new app.MenuPageView({
      model: self.model,
      page,
      level: self.level + 1,
    });
    cell.$el.append(pageView.render().$el);
    self.pageViewsById[page._id] = pageView;

    if (animated) {
      cell.$el.hide();
      cell.$el.slideDown(400, 'easeInOutCubic');
    } else if (pageView) {
      pageView.$el.hide();
    }
  },

  viewWasAddedToDom() {
    if (!this._viewWasAddedToDom) {
      this._viewWasAddedToDom = true;

      this.sectionFieldGroupView.viewWasAddedToDom();

      _.each(this.pageViewsById, (pageView) => {
        pageView.viewWasAddedToDom();
      });
    }
  },

  showDetailsView(detailsView) {
    if (this.tabView && !app.modalManager.mustShowModal()) {
      const anchoredModal = new app.AnchoredModalView({ view: detailsView });
      this.tabView.setRightView(anchoredModal, false, true);
    } else {
      app.modalManager.showModalWithView(detailsView);
    }
  },

  _save(opts) {
    const options = { patch: true };
    _.extend(options, opts || {});
    this.model.save(
      {
        // This param, as the name implies, is there purely to force a change
        // event from Backbone. The server will reject it anyway.
        __forceChangeEvent__: Math.random(),
        pages: this.model.attributes.pages,
        sectionIds: this.model.attributes.sectionIds,
      },
      options,
    );
  },

  vendorSubPageFields(origSchema, keepVendorIdField) {
    const schema = app.JsonHelper.deepClone(origSchema);
    const fields = {};
    _.each(schema.fields, (field, key) => {
      if (
        (keepVendorIdField && !(key === 'associatedVendorId')) ||
        (!keepVendorIdField && key === 'associatedVendorId')
      ) {
        return;
      }
      fields[key] = schema.fields[key];
    });
    schema.fields = fields;
    return schema;
  },

  render() {
    const self = this;

    this.$el.html(this.template({ level: this.level }));

    this.$heading = this.$('.card-header');
    this.$headingTitle = this.$heading.find('.title');
    this.$pageList = this.$('.page-list');
    this.$sectionList = this.$('.section-list');
    this.$allControlPanes = this.$('.control-pane');
    this.$controlPaneBoth = this.$('.control-pane.both');
    this.$controlPanePage = this.$('.control-pane.page');
    this.$controlPaneSection = this.$('.control-pane.section');
    this.$controlPaneSubPage = this.$('.control-pane.sub-page');

    this.$menuCoverControl = this.$('.menu-cover-control');
    this.$menuTimingControl = this.$('.menu-timing-control');
    this.$quizControl = this.$('.quiz-control');

    this.$fulfillmentMethodControl = this.$('.fulfillment-method-control');

    // Show download on top bar only
    if (this.level === 0) {
      const $downloadButton = $(
        // prettier-ignore
        '<button type="button" class="btn btn-sm btn-primary download">' +
          'Download as CSV' +
        '</button>',
      );
      this.$('.card-header .right-button-container').append($downloadButton);
      $downloadButton.on('click', () => {
        const reqId = $downloadButton.initLoadingButton(
          $downloadButton.html(),
          'Generating',
          'Done',
        );
        const url = `${this.model.url()}/csv`;
        app.makeRequest(
          'GET',
          url,
          null,
          () => {
            $downloadButton.loadingDidFinishSuccessfully(reqId);
            app.showSavedToast('Done, check your email!', true);
          },
          () => {
            $downloadButton.loadingDidFinishWithError(reqId);
          },
        );
      });
    }
    // Clean up form listeners
    if (this.sectionFieldGroupView) {
      this.stopListening(this.sectionFieldGroupView);
      this.sectionFieldGroupView.destroy();
    }
    if (this.subPageFieldGroupView) {
      this.stopListening(this.subPageFieldGroupView);
      this.subPageFieldGroupView.destroy();
    }

    // Handle Page with sub-pages
    let sectionFieldSchema;
    let shouldRenderSubPage = false;
    // Page with subPages, no sections, keep only associated vendor
    if (!this.page.sectionIds.length && this.level === 1 && _.size(this.page.pages)) {
      shouldRenderSubPage = true;
      sectionFieldSchema = this.vendorSubPageFields(menuPageSchema, true);
      // Menu section in sub-page, remove only associated vendor
    } else if (this.level > 1) {
      shouldRenderSubPage = true;
      sectionFieldSchema = this.vendorSubPageFields(menuPageSchema, false);
      // Normal page/section
    } else {
      sectionFieldSchema = app.removeVendorFields(app.location, menuPageSchema);
    }

    this.sectionFieldGroupView = new app.FieldGroupView({
      schema: sectionFieldSchema,
      model: this.model,
      forceShowLocalizationFields: true,
    });
    this.$controlPaneSection.append(this.sectionFieldGroupView.render().$el);
    if (shouldRenderSubPage) {
      this.subPageFieldGroupView = new app.FieldGroupView({
        schema: this.vendorSubPageFields(menuPageSchema, true),
      });
      this.$controlPaneSubPage.append(this.subPageFieldGroupView.render().$el);
      const val = this.subPageFieldGroupView.getValue();
      _.extend(val, { associatedVendorId: this.page.associatedVendorId ?? '' });
      this.subPageFieldGroupView.setValue(val);
    }
    this.sectionFieldGroupView.setValue(this.page, this.model);
    this._sectionIdCount = _.size(this.page.sectionIds);

    // Listen for changes at the top level, and apply to individual pages
    this.listenTo(
      this.subPageFieldGroupView,
      app.FieldGroupView.Events.FieldGroupDidChangeValue,
      (fieldGroup) => {
        const value = fieldGroup.getValue();
        const prevValue = app.JsonHelper.deepClone(self.page);
        // Update subPages
        _.each(self.page.pages, (subPage) => {
          _.extend(subPage, value);
        });
        // Update self
        _.extend(self.page, value);

        self._save({
          error() {
            fieldGroup.setValue(prevValue, self.model);
          },
          success() {
            window.app.showSavedToast();
          },
        });
      },
    );
    this.listenTo(
      this.sectionFieldGroupView,
      app.FieldGroupView.Events.FieldGroupDidChangeValue,
      (fieldGroup) => {
        const prevValue = app.JsonHelper.deepClone(self.page);
        const value = fieldGroup.getValue();

        if (_.size(self.model.getAllPages()) === 1 && value.showQuickNav) {
          value.showQuickNav = false;
          fieldGroup.setValue(value, self.model);
          new app.AlertView().show(
            'Setting `show quick nav buttons` not allowed on menu with only one page.',
          );
          return;
        }

        // Automatically enable quick nav for pages with >= 5 sections
        const newSectionIdCount = _.size(value.sectionIds);
        if (newSectionIdCount === 5 && self._sectionIdCount === 4) {
          value.showQuickNav = true;
          fieldGroup.setValue(value, self.model);
        }

        _.extend(self.page, value);
        self._sectionIdCount = newSectionIdCount;
        self._save({
          error() {
            fieldGroup.setValue(prevValue, self.model);
          },
          success() {
            self.showAppropriateControlPane();
            window.app.showSavedToast();
          },
        });
      },
    );

    this.setUpControlPane();
    this.showAppropriateControlPane();

    app.activateTooltips(this.$el);

    _.each(this.page.pages, (page) => {
      this.addPage(page);
    });

    if (app.sessionUser.canManageMenu()) {
      Sortable.create(self.$pageList[0], {
        handle: '.handle',
        delay: app.sortableDelay(),
        draggable: '.sortable',
        ghostClass: 'ghost',
        onEnd(e) {
          const oldIndex = e.oldIndex >= 0 ? e.oldIndex : -1;
          const newIndex = e.newIndex >= 0 ? e.newIndex : -1;
          if (oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
            self.movePage(oldIndex, newIndex);
          }
        },
      });
    }

    return this;
  },
});
