import Backbone from 'backbone';
import { Modal } from 'bootstrap';
import _ from 'underscore';

app.AlertView = Backbone.View.extend({
  initialize() {
    this.setElement($('#alert-modal'));
  },

  _adjustSizeToFitWindow(e) {
    let bodyHeight = window.innerHeight || $('body')[0].clientHeight;
    bodyHeight -= 30 * 2;
    bodyHeight -= 2; // borders
    this.$body.css('max-height', `${bodyHeight}px`);

    // Try to center the modal
    let margin = 30;
    let outerHeight = this.$body.outerHeight();
    if (e.type && e.type === 'show' && outerHeight === 0) {
      outerHeight = 55;
    }
    if (outerHeight > 0 && outerHeight < bodyHeight) {
      margin += Math.floor((bodyHeight - outerHeight) / 2);
    }
    this.$('.modal-dialog').css('margin-top', `${margin}px`);
  },

  showWarnings(warnings, title = `Warning:`) {
    if (warnings.length) {
      const warningList = _.map(warnings, (warning) => {
        return `<li>${warning}</li>`;
      }).join('');
      this.show(`<span class="text-danger">${title}</span>`, `<br /><ol>${warningList}</ol>`);
    } else {
      this.show(title);
    }
  },

  show(text, $content, confirmCallback, cancelCallback, confirmationText, options = {}) {
    const { hideConfirmButton } = options;

    this.$body = this.$('.modal-body');
    this.$container = this.$body.find('.custom-body');
    this.$('.modal-title').html(text.split('\n').join('<br />'));

    this.$container.html($content || '');

    this.$('.modal-footer button.cancel').toggle(!!cancelCallback);
    this.$('.modal-footer button.confirm').toggle(!hideConfirmButton);

    // Hide the footer if both buttons are hidden
    this.$('.modal-footer').toggle(!hideConfirmButton || !!cancelCallback);

    this.$('.modal-footer button.confirm').off();
    if (confirmCallback) {
      this.$('.modal-footer button.confirm').click(() => {
        confirmCallback();
        // Don't return false here; otherwise modal won't close
      });
    }
    this.$('.modal-footer button.cancel').off();
    if (cancelCallback) {
      this.$('.modal-footer button.cancel').click(() => {
        cancelCallback();
        // Don't return false here; otherwise modal won't close
      });
    }

    $(window).on('resize', this._adjustSizeToFitWindow.bind(this));
    this.el.addEventListener('show.bs.modal', this._adjustSizeToFitWindow.bind(this));
    this.el.addEventListener('shown.bs.modal', this._adjustSizeToFitWindow.bind(this));
    this.el.addEventListener('hidden.bs.modal', () => {
      Modal.getOrCreateInstance(this.el).dispose();
    });

    if (confirmationText) {
      this.$('.modal-footer .confirmation').text(confirmationText);
    } else {
      this.$('.modal-footer .confirmation').text('');
    }

    Modal.getOrCreateInstance(this.el).show();
    // this.$el.modal('show');
  },
});

app.AlertView.showModelList = (models, text, confirmationText, confirmCallback) => {
  const $content = $('<div class="dupe-items"></div>');
  _.each(models, (model) => {
    const $model = $(
      '<div class="dupe-item">' +
        '<div class="img"></div>' +
        '<div class="text">' +
        '<div class="name"></div>' +
        '<div class="visible-in"></div>' +
        '<div class="hidden-status"></div>' +
        '</div>' +
        '</div>',
    );

    if (model.hasArr('images')) {
      $model.find('.img').css({
        'background-size': 'cover',
        'background-image': `url(${model.get('images')[0].url})`,
      });
    }
    $model.find('.name').html(model.displayNameHtml());

    const visibleInPlaces = model.getVisibleInPlaces();
    if (visibleInPlaces.length) {
      const visibleInHtml = _.map(visibleInPlaces, (place) => {
        if (_.isString(place)) {
          return place;
        }
        return place.displayNameHtml();
      }).join(', ');
      $model.find('.visible-in').html(`Appears in: ${visibleInHtml}`);
    } else {
      $model.find('.visible-in').html('Currently not on the menu');
    }
    if (app.location.get('multiVendorSupport')) {
      const integrationId = _.first(model.get('integrations'))?._id;
      if (integrationId) {
        const i9n = app.integrationList.get(integrationId);
        if (i9n) {
          const vendor = app.vendorList.get(i9n.get('vendorId'));
          if (vendor) {
            // vendor might not be loaded yet
            $model.find('.visible-in').append(`<br>Vendor: ${vendor.displayName()}`);
          }
        }
      }
    }

    $content.append($model);
  });

  const alertView = new app.AlertView();
  alertView.show(text, $content, confirmCallback, () => {}, confirmationText);
};
