import type { Schema } from '@biteinc/common';
import { allEnumValues, OrderChannel, ReportType } from '@biteinc/enums';

export const oneTimeReportSchema: Schema.Model = {
  displayName: 'one time report',
  type: 'object',
  fields: {
    type: {
      type: 'keyString',
      required: true,
      displayName: 'Report Type',
      ui: 'dropdown',
    },
    locationGroupId: {
      type: 'mongoId',
      displayName: 'Locations',
      ui: 'dropdown',
      required: true,
      conditions: [
        {
          field: 'type',
          values: [
            ReportType.CouponRedemptionHistory,
            ReportType.EightySixingActivity,
            ReportType.FlashPayouts,
            ReportType.FutureOrders,
            ReportType.ItemUpsell,
            ReportType.PilotEngagement,
            ReportType.PilotLaborAnalysis,
            ReportType.SalesGuests,
            ReportType.SalesOrders,
            ReportType.SalesSummaryOrg,
            ReportType.SalesTransactions,
            ReportType.ThirdPartyOrders,
          ],
        },
      ],
    },
    timezone: {
      type: 'keyString',
      ui: 'dropdown',
      required: true,
      tooltip: {
        text: 'All dates in your report will appear in this timezone.',
      },
      conditions: [
        {
          field: 'type',
          values: [
            ReportType.CouponRedemptionHistory,
            ReportType.CustomerOrders,
            ReportType.EightySixingActivity,
            ReportType.FlashPayouts,
            ReportType.FutureOrders,
            ReportType.ItemUpsell,
            ReportType.PilotEngagement,
            ReportType.PilotLaborAnalysis,
            ReportType.SalesGuests,
            ReportType.SalesOrders,
            ReportType.SalesSummaryOrg,
            ReportType.SalesTransactions,
            ReportType.ThirdPartyOrders,
          ],
        },
      ],
    },
    timeRange: {
      type: 'object',
      subtype: 'DateRange',
      required: true,
      displayName: 'Date Range',
      conditions: [
        {
          field: 'type',
          values: allEnumValues<ReportType>(ReportType).filter((reportType) => {
            return ![ReportType.VitrineCompatibility, ReportType.KioskPeripherals].includes(
              reportType,
            );
          }),
        },
      ],
    },
    salesByLocationGroupIds: {
      type: 'array',
      elementType: 'mongoId',
      displayName: 'Locations Groups to Include in "Sales by Location Group" Section',
      ui: 'table',
      placeholder: 'Select Location Groups',
      conditions: [
        {
          field: 'type',
          values: [ReportType.SalesSummaryOrg],
        },
      ],
    },
    emailAddresses: {
      type: 'array',
      elementType: 'email',
      displayName: 'Email Addresses',
      required: true,
      minCount: 1,
      tooltip: {
        text: "The final report will be sent to this email address once it's fully processed.",
      },
      conditions: [
        {
          field: 'type',
          values: allEnumValues<ReportType>(ReportType).filter((reportType) => {
            return reportType !== ReportType.SalesSummaryModal;
          }),
        },
      ],
    },
    orderChannels: {
      displayName: 'Order Channels',
      type: 'array',
      elementType: 'stringEnum',
      elementEnum: OrderChannel,
      required: true,
      minCount: 1,
      ui: 'checklist',
      orConditions: [
        {
          field: 'type',
          level: 'org',
          values: [
            ReportType.CouponRedemptionHistory,
            ReportType.PilotEngagement,
            ReportType.PilotLaborAnalysis,
          ],
        },
        {
          field: 'type',
          level: 'site',
          values: [
            ReportType.CouponRedemptionHistory,
            ReportType.EightySixingActivity,
            ReportType.FutureOrders,
            ReportType.ItemUpsell,
            ReportType.PilotEngagement,
            ReportType.PilotLaborAnalysis,
            ReportType.SalesGuests,
            ReportType.SalesOrders,
            ReportType.SalesSummarySite,
            ReportType.SalesTransactions,
            ReportType.ThirdPartyOrders,
          ],
        },
      ],
    },
    isBiteOnly: {
      type: 'bool',
      displayName: 'Is Bite-Only',
      biteOnly: true,
      tooltip: {
        text: 'Generated report will include Bite-Only data',
      },
      conditions: [
        {
          field: 'type',
          values: [
            ReportType.SalesSummaryChannel,
            ReportType.SalesSummarySite,
            ReportType.SalesSummaryOrg,
          ],
        },
      ],
    },
    vendorId: {
      type: 'mongoId',
      displayName: 'Vendor',
      ui: 'dropdown',
      required: true,
      conditions: [
        {
          field: 'type',
          values: [ReportType.VendorPayouts],
        },
      ],
    },
  },
};
