import _ from 'underscore';

import { VitrineCoverBlockType } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';
import { menuCoverSchema } from '@biteinc/schemas';

const { fields: DisplayBlockFields } = menuCoverSchema.fields.portraitBlocks;

app.MenuCoverDisplayBlockView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {};
      },
      getTitle: (fieldGroupView) => {
        const { type } = fieldGroupView.getValue();

        if (!type) {
          return '';
        }

        let titleAppendix = '';
        if (type === VitrineCoverBlockType.Pathway) {
          titleAppendix = ` - ${fieldGroupView
            .getValue()
            .pathways?.map((p) => StringHelper.toTitleCase(p.linkType))
            .join(', ')}`;
        }

        return (
          type
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ') + titleAppendix
        );
      },
      getElementSchemaFields: (/* element */) => {
        return DisplayBlockFields;
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new cover block',
        emptyText: 'no cover blocks yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
