import { LocationState } from '@biteinc/enums';

import type { BiteLevelOrgData, Org } from './types/org_data';

export function getCsvLinesFromOrgList(
  orgList: Backbone.Collection<Org<BiteLevelOrgData>>,
  query: string,
  countLiveLocationsThatMatchQuery: boolean,
  callback: { (err: Error | null, csvLines: string[][]): void },
): void {
  const lines: string[][] = [];
  lines.push([
    'Org Name',
    'Admin Link',
    'Live Location Count',
    'Live Kiosk Count',
    'Contact Info',
    'Web Domain',
    'Catering Domain',
  ]);
  orgList.models.forEach((org) => {
    if (query && !org.matchesQuery(query)) {
      return;
    }

    const liveLocations = countLiveLocationsThatMatchQuery
      ? org.getLocationsThatMatchQuery(query, true)
      : org.get('locations')!.filter((location) => {
          return location.state === LocationState.Live;
        });
    const kioskCount = liveLocations.reduce((acc, location) => {
      return acc + location.kioskCount;
    }, 0);

    lines.push([
      org.get('name')!,
      `${window.location.origin}${org.bureauUrl()}`,
      `${liveLocations.length}`,
      `${kioskCount}`,
      org.get('contactInfo') || '',
      org.get('webCustomDomain')?.domain || '',
      org.get('cateringCustomDomain')?.domain || '',
    ]);
  });

  callback(null, lines);
}
