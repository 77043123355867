import _ from 'underscore';

import { MenuPageDesignation, ModelType } from '@biteinc/enums';
import { menuStructureSchema } from '@biteinc/schemas';

import { getArchivedBadgeHtml } from '../helpers/html_builder';

app.MenuStructure = app.AbstractModel.extend({
  ModelName: 'menuStructure',
  Schema: menuStructureSchema,
  Type: ModelType.MenuStructure,

  defaults() {
    return {
      menuId: app.menu.id,
    };
  },

  pathsToSectionWithId(sectionId) {
    const paths = [];
    const pagePaths = this._pathsToSectionWithIdInPage(sectionId, this.attributes);
    _.each(pagePaths, (pathComponents) => {
      paths.push(pathComponents.join(' > '));
    });
    return paths;
  },

  _pathsToSectionWithIdInPage(sectionId, page) {
    const self = this;
    let paths = [];
    if (!page) {
      return paths;
    }
    if (_.size(page.sectionIds)) {
      if (_.contains(page.sectionIds, sectionId)) {
        paths = [[page.name]];
      }
    } else {
      _.each(page.pages || [], (subPage) => {
        const subPaths = self._pathsToSectionWithIdInPage(sectionId, subPage);
        _.each(subPaths || [], (subPath) => {
          subPath.unshift(page.name);
          paths.push(subPath);
        });
      });
    }

    return paths;
  },

  removeMenuSection(menuSection) {
    this._removeSectionIdFromPage(menuSection.id, this.attributes);
  },

  _removeSectionIdFromPage(sectionId, page) {
    if (!page) {
      return false;
    }

    let removed = false;
    if (_.size(page.sectionIds)) {
      for (let i = 0; i < page.sectionIds.length; i++) {
        if (sectionId === page.sectionIds[i]) {
          page.sectionIds.splice(i, 1);
          return true;
        }
      }
    } else {
      _.each(page.pages || [], (p) => {
        const status = this._removeSectionIdFromPage(sectionId, p);
        removed = removed || status;
      });
    }
    return removed;
  },

  // Return true if the specified menu item is included in any of the
  // sections that are displayed on the menu.
  hasVisibleMenuItem(menuItem) {
    return this._pageContainsVisibleMenuItem(this.attributes, menuItem);
  },

  _pageContainsVisibleMenuItem(page, menuItem) {
    if (_.size(page.pages)) {
      for (let i = 0; i < page.pages.length; i++) {
        if (this._pageContainsVisibleMenuItem(page.pages[i], menuItem)) {
          return true;
        }
      }
    } else if (_.size(page.sectionIds)) {
      for (let j = 0; j < page.sectionIds.length; j++) {
        const section = app.menuSectionList.get(page.sectionIds[j]);
        if (section && section.hasVisibleMenuItem(menuItem)) {
          return true;
        }
      }
    }
    return false;
  },

  detailsViewClassForListField(field) {
    if ('sectionIds' === field) {
      return app.MenuItemArrayDetailsView;
    }
    return null;
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = subProperty ? `${subProperty}.${field}` : field;

    switch (fieldName) {
      case 'menuCoverId':
        return app.menuCoverList;
      case 'menuTimingId': {
        const menuTimingsClones = app.menuTimingList.clone();
        // Add a dropdown option that allows the user to remove the timing from the structure
        menuTimingsClones.add({
          name: 'No assigned day part (menu will not be shown)',
          isUnset: true,
        });
        return menuTimingsClones;
      }
      case 'designation':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuPageDesignation,
          nameGenerator: (designation) => {
            switch (designation) {
              case MenuPageDesignation.Regular:
                return 'Regular Page';
              case MenuPageDesignation.SecretMenu:
                return 'Secret Menu';
            }
          },
        });
      case 'sectionIds':
        return app.menuSectionList;
      case 'fulfillmentMethods':
        return app.location.getCollectionFromExistingFulfillmentMethods(
          true /* showDisabledState */,
        );
      case 'quiz.quizIds':
        return app.quizList;
    }
    return null;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    if ('sectionIds' === field) {
      const archivedBadgeHtml = getArchivedBadgeHtml(
        element.get('archivedAt'),
        element.isModGroup(),
      );
      if (archivedBadgeHtml) {
        return `${displayName} ${archivedBadgeHtml}`;
      }
    }
    return displayName;
  },

  getAllPages() {
    const pages = [];
    _.each(this.get('pages'), (page) => {
      pages.push(page);
      if (_.size(page.pages)) {
        _.each(page.pages, (subPage) => {
          pages.push(subPage);
        });
      }
    });
    return pages;
  },
});
