import { createLocationGuidedSchema } from './schemas/create_location_guided_schema';

app.NewLocationGuidedDetailsView = app.BaseDetailsView.extend({
  _shouldHideAppearanceDropdown() {
    return app.orgAppearanceList.size() === 1;
  },

  getSchema() {
    const schema = createLocationGuidedSchema;
    if (this._shouldHideAppearanceDropdown()) {
      schema.fields.orgAppearanceId.isHidden = true;
    }
    return schema;
  },

  _save(data, opts, $saveButton) {
    if (this._shouldHideAppearanceDropdown()) {
      app.BaseDetailsView.prototype._save.apply(this, [
        { ...data, orgAppearanceId: app.orgAppearanceList.at(0).id },
        opts,
        $saveButton,
      ]);
    } else {
      app.BaseDetailsView.prototype._save.apply(this, [data, opts, $saveButton]);
    }
  },

  _didSaveModel() {
    app.showSavedToast('Created a New Location!');
    setTimeout(() => {
      window.location.href = `${this.model.bureauUrl()}?create-location-step-by-step=true`;
    }, 1500);
  },
});
