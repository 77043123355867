import type Backbone from 'backbone';

import { I9nSchemaBySystem } from '@biteinc/common';
import {
  allEnumValues,
  CardEntryMethod,
  CardEntryMethodHelper,
  CardSchemeId,
  CardSchemeIdHelper,
  FulfillmentMethod,
  FulfillmentMethodHelper,
  Gateway,
  GatewayHelper,
  IntegrationSystem,
  KioskAvailability,
  KioskAvailabilityHelper,
  KioskDeviceType,
  KioskDeviceTypeHelper,
  KioskPaymentTerminalModel,
  KioskPaymentTerminalModelHelper,
  mapEnumToRecord,
  ModelType,
  OrderPaymentDestination,
  OrderPaymentDestinationHelper,
  OrderPrintState,
  OrderPrintStateHelper,
  PrinterType,
  PrinterTypeHelper,
  TransactionResult,
  TransactionResultHelper,
  TransactionState,
  TransactionStateHelper,
  TransactionType,
  TransactionTypeHelper,
  TransactionUserAction,
  TransactionUserActionHelper,
} from '@biteinc/enums';

import type { AbstractModelData } from '../types/abstract_model_data';
import type { MenuItemData } from '../types/menu_types';
import type { UserData } from '../types/user_data';

function getNumberEnumNameByValue<EnumType extends number>(
  tsEnum: any,
  nameGenerator: (value: EnumType) => string,
): Record<number, string> {
  return mapEnumToRecord<EnumType, string>(tsEnum, nameGenerator);
}

export module ModelHelper {
  export function getCollectionsForModelType(
    modelType: ModelType,
  ): Backbone.Collection<Backbone.Model<AbstractModelData>>[] {
    switch (modelType) {
      case ModelType.MenuItem:
        return [app.menuItemList];
      case ModelType.MenuSection:
        return [app.menuSectionList];
      case ModelType.ModGroup:
        return [app.modGroupList];
      case ModelType.Badge:
        return [app.badgeList];
      case ModelType.DictionaryWord:
        return [app.dictionaryWordList];
      case ModelType.MenuCover:
        return [app.menuCoverList];
      case ModelType.MenuCoverPromo:
        return [app.menuCoverPromoList];
      case ModelType.MenuStructure:
        return [app.menuStructureList];
      case ModelType.MenuTiming:
        return [app.menuTimingList];
      case ModelType.User: {
        const collections = [app.userList];
        if (app.apiTokenList) {
          collections.push(app.apiTokenList);
        }
        return collections;
      }
      case ModelType.Quiz:
        return [app.quizList];
      case ModelType.QuizStep:
        return [app.quizStepList];
      case ModelType.Mod:
        return [app.modList];
      case ModelType.Location:
        return [app.locationList];
      case ModelType.TaxProfile:
        return [app.taxProfileList];
      case ModelType.Kiosk:
        return [app.kioskList];
      case ModelType.ReportSchedule:
        return [app.reportScheduleList];
      case ModelType.Order:
        return [app.orderList];
      case ModelType.Integration:
        return [app.integrationList];
      case ModelType.Vendor:
        return [app.vendorList];
      case ModelType.Coupon:
        return [app.couponList];
      case ModelType.LocationGroup:
        return [app.locationGroupList];
      case ModelType.UserRole:
        return [app.userRoleList];
      case ModelType.OpenHoursOverride:
        return [app.openHoursOverrideList];
      case ModelType.OpenHoursTimetable:
        return [app.openHoursTimetableList];
      default:
        return [];
    }
  }

  export function getDetailViewClassForModelType<ModelData extends AbstractModelData>(
    modelType: ModelType,
    model?: Backbone.Model<ModelData>,
  ): typeof Backbone.View<Backbone.Model<ModelData>> {
    switch (modelType) {
      case ModelType.Coupon:
        return app.CouponDetailsView;
      case ModelType.Integration:
        return app.IntegrationDetailsView;
      case ModelType.Kiosk:
        return app.KioskDetailsView;
      case ModelType.Mod:
      case ModelType.MenuItem:
        return (model as Backbone.Model<MenuItemData>).get('constituentItems')
          ? app.CompoundMenuItemDetailsView
          : app.MenuItemDetailsView;
      case ModelType.ModGroup:
      case ModelType.MenuSection:
        return app.MenuItemArrayDetailsView;
      case ModelType.Order:
        return app.ModelJsonView;
      case ModelType.RecommendationRule:
        return app.RecommendationRuleDetailsView;
      case ModelType.RecommendationVariation:
        return app.RecommendationVariationDetailsView;
      case ModelType.ReportSchedule:
        return app.ReportScheduleDetailsView;
      case ModelType.Vendor:
        return app.VendorDetailsView;
      case ModelType.User:
        return (model as Backbone.Model<UserData>).get('token')
          ? app.ApiTokenDetailsView
          : app.UserDetailsView;
      case ModelType.UserRole:
        return app.UserRoleDetailsView;
    }
    return app.BaseDetailsView;
  }

  export function getModelForType(
    modelType: ModelType,
  ): typeof Backbone.Model<AbstractModelData> | null {
    switch (modelType) {
      case ModelType.MenuItem:
      case ModelType.Mod:
        return app.MenuItem;
      case ModelType.MenuSection:
        return app.MenuSection;
      case ModelType.ModGroup:
        return app.ModGroup;
      case ModelType.Badge:
        return app.Badge;
      case ModelType.DictionaryWord:
        return app.DictionaryWord;
      case ModelType.MenuCover:
        return app.MenuCover;
      case ModelType.MenuCoverPromo:
        return app.MenuCoverPromo;
      case ModelType.MenuStructure:
        return app.MenuStructure;
      case ModelType.MenuTiming:
        return app.MenuTiming;
      case ModelType.User:
        return app.User;
      case ModelType.Location:
        return app.Location;
      case ModelType.TaxProfile:
        return app.TaxProfile;
      case ModelType.Kiosk:
        return app.Kiosk;
      case ModelType.ReportSchedule:
        return app.ReportSchedule;
      case ModelType.Org:
        return app.Org;
      case ModelType.Integration:
        return app.Integration;
      case ModelType.Vendor:
        return app.Vendor;
      case ModelType.Coupon:
        return app.Coupon;
      case ModelType.LocationGroup:
        return app.LocationGroup;
      case ModelType.UserRole:
        return app.UserRole;
      case ModelType.OpenHoursOverride:
        return app.OpenHoursOverride;
      case ModelType.OpenHoursTimetable:
        return app.OpenHoursTimetable;
      case ModelType.Order:
        return app.Order;
      case ModelType.Quiz:
        return app.Quiz;
      case ModelType.QuizStep:
        return app.QuizStep;
      default:
        return null;
    }
  }

  export function guessModelTypeFromModelId(modelId: string): ModelType | undefined {
    return allEnumValues<ModelType>(ModelType).find((type) => {
      const collections = ModelHelper.getCollectionsForModelType(type);
      return collections.some((collection) => {
        return !!collection.get(modelId);
      });
    });
  }

  export function getCollectionAndModelWithIdAndType<ModelData extends AbstractModelData>(
    modelId: string,
    modelType: ModelType,
  ):
    | {}
    | {
        model: Backbone.Model<ModelData>;
        collection: Backbone.Collection<Backbone.Model<ModelData>>;
      } {
    const collections = ModelHelper.getCollectionsForModelType(modelType);
    for (let i = 0; i < collections.length; i++) {
      const collection = collections[i];
      const model = collection.get(modelId);
      if (model) {
        return { model, collection };
      }
    }
    return {};
  }

  export function getPreCollapsedJsonFieldsForModelType(modelType: ModelType): string[] {
    switch (modelType) {
      case ModelType.Order:
        return ['transactions'];
      default:
        return [];
    }
  }

  export function getNumberEnumLookupMapForModelType(
    modelType: ModelType,
  ): Record<string, Record<number, string>> {
    switch (modelType) {
      case ModelType.Kiosk: {
        return {
          availability: getNumberEnumNameByValue<KioskAvailability>(
            KioskAvailability,
            KioskAvailabilityHelper.name,
          ),
          deviceType: getNumberEnumNameByValue<KioskDeviceType>(
            KioskDeviceType,
            KioskDeviceTypeHelper.name,
          ),
          printerType: getNumberEnumNameByValue<PrinterType>(PrinterType, PrinterTypeHelper.name),
          paymentTerminalModel: getNumberEnumNameByValue<KioskPaymentTerminalModel>(
            KioskPaymentTerminalModel,
            KioskPaymentTerminalModelHelper.name,
          ),
        };
      }
      case ModelType.Order: {
        return {
          system: getNumberEnumNameByValue<IntegrationSystem>(IntegrationSystem, (system) => {
            return I9nSchemaBySystem[system].name;
          }),
          fulfillmentMethod: getNumberEnumNameByValue<FulfillmentMethod>(
            FulfillmentMethod,
            FulfillmentMethodHelper.nameWithChannel,
          ),
          paymentDestination: getNumberEnumNameByValue<OrderPaymentDestination>(
            OrderPaymentDestination,
            OrderPaymentDestinationHelper.stringFromEnum,
          ),
          expoPrintState: getNumberEnumNameByValue<OrderPrintState>(
            OrderPrintState,
            OrderPrintStateHelper.name,
          ),
        };
      }
      case ModelType.Transaction:
        return {
          cardEntryMethod: getNumberEnumNameByValue<CardEntryMethod>(
            CardEntryMethod,
            CardEntryMethodHelper.name,
          ),
          cardSchemeId: getNumberEnumNameByValue<CardSchemeId>(
            CardSchemeId,
            CardSchemeIdHelper.name,
          ),
          gateway: getNumberEnumNameByValue<Gateway>(Gateway, GatewayHelper.name),
          result: getNumberEnumNameByValue<TransactionResult>(
            TransactionResult,
            TransactionResultHelper.stringFromEnum,
          ),
          state: getNumberEnumNameByValue<TransactionState>(
            TransactionState,
            TransactionStateHelper.stringFromEnum,
          ),
          type: getNumberEnumNameByValue<TransactionType>(
            TransactionType,
            TransactionTypeHelper.stringFromEnum,
          ),
          userAction: getNumberEnumNameByValue<TransactionUserAction>(
            TransactionUserAction,
            TransactionUserActionHelper.stringFromEnum,
          ),
        };
      default:
        return {};
    }
  }
}
