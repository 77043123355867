export function createLocationGuidedHelper(): void {
  const referenceLocationUsageCount = new Map<string, number>();

  app.locationList.forEach((location) => {
    if (location.attributes.menuReferenceLocationId) {
      const referenceLocationId = location.attributes.menuReferenceLocationId;
      referenceLocationUsageCount.set(
        referenceLocationId,
        (referenceLocationUsageCount.get(referenceLocationId) || 0) + 1,
      );
    }
  });

  const mostUsedReferenceLocationId = Array.from(referenceLocationUsageCount.entries()).reduce(
    (current, bestSoFar) => (current[1] > bestSoFar[1] ? current : bestSoFar),
    [null, 0] as [string | null, number],
  )[0];

  const wipLocationModel = new app.locationList.model(
    {
      siteId: app.site.id,
      ...(mostUsedReferenceLocationId && {
        menuReferenceLocationId: mostUsedReferenceLocationId,
      }),
    },
    {
      collection: app.locationList,
    },
  );

  const detailsView = new app.NewLocationGuidedDetailsView({
    collection: app.locationList,
    model: wipLocationModel,
  });
  app.modalManager.showModalWithView(detailsView);
}
