import type { Schema } from '@biteinc/common';
import { OrderChannel } from '@biteinc/enums';

export const createLocationGuidedSchema: Schema.Model = {
  displayName: 'location',
  type: 'object',
  fields: {
    orderChannel: {
      displayName: 'Order Channel',
      type: 'stringEnum',
      enum: OrderChannel,
      required: true,
      ui: 'radiolist',
      defaultValue: OrderChannel.Kiosk,
    },
    menuReferenceLocationId: {
      displayName: 'Menu Reference Location',
      type: 'mongoId',
      required: true,
      biteOnly: true,
      ui: 'dropdown',
    },
    orgAppearanceId: {
      type: 'mongoId',
      displayName: 'Org Appearance',
      required: true,
      ui: 'dropdown',
    },
    phoneNumber: {
      type: 'phone',
      displayName: 'Phone Number',
    },
    fullAddress: {
      type: 'object',
      subtype: 'Address',
      displayName: 'Full Civic Address',
    },
  },
};
