import async from 'async';
import Backbone from 'backbone';
import _ from 'underscore';

import { allEnumValues } from '@biteinc/enums';
import { StringHelper, Time } from '@biteinc/helpers';

app.AbstractCollection = Backbone.Collection.extend({
  model: app.AbstractModel,
  _hasBeenFetched: false,
  _hasReachedEndOfList: false,
  _nextUrl: null,
  _options: null,
  query: '',

  modelId() {
    const id = Backbone.Collection.prototype.modelId.apply(this, arguments);
    if (_.isNumber(id)) {
      return `${id}`;
    }
    return id;
  },

  initialize(models, options) {
    Backbone.Collection.prototype.initialize.apply(this, arguments);

    if (models?.length > 0) {
      this._hasBeenFetched = true;
    }

    this.on('reset', () => {
      this._hasBeenFetched = true;
    });

    this._options = _.extend(
      {
        sort: app.AbstractCollection.SortOptions.NAME,
      },
      options || {},
    );

    if (this._options.url) {
      this.url = this._options.url;
    }
  },

  usesPaginatedFetch() {
    return false;
  },

  fetch(opts) {
    const options = opts || {};
    if (!options.reset && this._nextUrl && !options.url) {
      options.url = this._nextUrl;
    }

    if (options.reset && this.usesPaginatedFetch()) {
      options.paginatedFetchOptions = {
        key: StringHelper.pluralize(this.model.prototype.ModelName),
      };
    }

    Backbone.Collection.prototype.fetch.apply(this, [options]);
  },

  fetchWithFilter(params, onFetchComplete) {
    const paramsStr = Object.entries(params)
      .filter(([, value]) => {
        return value !== null && value !== undefined;
      })
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join('&');

    this.fetch({
      url: `${this.url()}${paramsStr ? `?${paramsStr}` : ''}`,
      // When we fetch with a filter, we want to wipe the current collection because it may contain
      // elements that do not match the filter.
      // For pagination, we will rely on the _nextUrl property to have the params.
      reset: true,
      success() {
        onFetchComplete();
      },
      error(collection, err) {
        onFetchComplete(err);
      },
    });
  },

  fetchModelWithId(modelId, callback) {
    this.fetch({
      url: `${this.url()}/${modelId}`,
      remove: false,
      showErrorModal: false,
      error(collection, err) {
        callback(err);
      },
    });
  },

  hasBeenFetched() {
    return !!this._hasBeenFetched;
  },

  hasReachedEndOfList() {
    return this._hasReachedEndOfList;
  },

  getModels() {
    return this.models;
  },

  getSchema() {
    return this.model.prototype.Schema;
  },

  type() {
    const schema = this.getSchema();
    return schema ? StringHelper.pluralize(schema.displayName) : null;
  },

  parse(data) {
    if (data.data.next) {
      this._nextUrl = data.data.next;
    } else {
      this._nextUrl = null;
    }
    this._hasReachedEndOfList = !this._nextUrl;
    if (data.data[this.model.prototype.ModelName]) {
      return [data.data[this.model.prototype.ModelName]];
    }
    // this is the most savage thing I've ever seen. godless.
    return data.data[StringHelper.pluralize(this.model.prototype.ModelName)];
  },

  comparator(model) {
    // Sort archived models to the bottom
    // If we are sorting by a number then adding 0 won't do anything and will preserve the number.
    // Adding an empty string, turns the whole thing into a string and screws up number sorting.
    const sortKey = model.get('archivedAt') ? '~' : 0;
    switch (this._options.sort) {
      case app.AbstractCollection.SortOptions.NAME:
        return sortKey + model.displayName().toLowerCase();
      case app.AbstractCollection.SortOptions.CREATED_AT_DESC:
        return sortKey + model.get('createdAt') * -1;
      case app.AbstractCollection.SortOptions.ID:
        return sortKey + model.id;
      case app.AbstractCollection.SortOptions.AS_IS:
        return sortKey;
      case app.AbstractCollection.SortOptions.WORK_WEEKDAYS:
        return Time.WORK_WEEK_FULL_DAYS.indexOf(model.displayName());
      default:
        return sortKey + model.id;
    }
  },

  create(attrs, opts) {
    const options = _.extend(
      {
        wait: true,
      },
      opts || {},
    );
    Backbone.Collection.prototype.create.apply(this, [attrs, options]);
  },

  isValidQuery(query) {
    return query.length > 3;
  },

  performSearchByQuery(query) {
    this.query = query;

    this._hasBeenFetched = false;
    this._hasReachedEndOfList = false;
    this._nextUrl = null;

    this.fetch({ reset: true });
  },

  buildDataSource() {
    return new app.DataSource([], { collection: this });
  },
});

app.DataSource = app.AbstractCollection.extend({
  initialize(models, options) {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);

    if (options.collection) {
      this._collections = [options.collection];
      this._mainCollection = options.collection;
    } else {
      this._collections = options.collections;
      this._mainCollection = options.mainCollection || options.collections[0];
    }

    // Listen to sub-collections events and propagate them
    const self = this;
    const events = ['reset', 'add', 'remove', 'update'];
    _.each(this._collections, (collection) => {
      _.each(events, (event) => {
        self.listenTo(collection, event, function onEvent() {
          const args = Array.prototype.slice.call(arguments);
          args.unshift(event);
          app.DataSource.prototype.trigger.apply(self, args);
        });
      });
    });
  },

  getCollections() {
    return [...this._collections].concat();
  },

  getMainCollection() {
    return this._mainCollection;
  },

  getModels() {
    return this._collections.reduce((models, collection) => {
      return [...models, ...collection.getModels()];
    }, []);
  },

  fetch(opts) {
    this._collections.forEach((collection) => {
      collection.fetch(opts);
    });
  },

  fetchModelWithId(modelId, callback) {
    let firstErr = null;
    let callbackHasBeenCalled = false;
    async.each(
      this._collections,
      (collection, cb) => {
        collection.fetchModelWithId(modelId, (err) => {
          if (!err && !callbackHasBeenCalled) {
            // Call the final callback as soon as we have a positive result
            callbackHasBeenCalled = true;
            callback();
          } else if (err && !firstErr) {
            firstErr = err;
          }
          cb();
        });
      },
      () => {
        if (!callbackHasBeenCalled) {
          callback(firstErr);
        }
      },
    );
  },

  hasBeenFetched() {
    return this._collections.every((collection) => {
      return collection.hasBeenFetched();
    });
  },

  size() {
    return this._collections.reduce((size, collection) => {
      return size + collection.size();
    }, 0);
  },

  getSchema() {
    return this._mainCollection.model.prototype.Schema;
  },

  get(modelId) {
    for (let i = 0; i < this._collections.length; i++) {
      const model = this._collections[i].get(modelId);
      if (model) {
        return model;
      }
    }
    return null;
  },
});

app.AbstractCollection.SortOptions = {
  NAME: 1,
  ID: 2,
  CREATED_AT_DESC: 3,
  AS_IS: 4,
  WORK_WEEKDAYS: 5,
};

/**
 * @description Creates a collection with enum values from the specified enum schema
 * @param {Object} schema enum schema
 * @param {Array<string>} values if specified then only enums in this array will be
 * included in the collection
 * @param {Class extends AbstractModel} ModelClass
 * @param {AbstractCollection.SortOptions} sort
 * @returns {AbstractCollection}
 */
app.AbstractCollection.createFromEnum = ({
  schema,
  values,
  ModelClass = app.AbstractModel,
  sort = app.AbstractCollection.SortOptions.ID,
}) => {
  const models = [];
  _.each(schema, (schemaValue) => {
    if (!values || values.indexOf(schemaValue._id) >= 0) {
      models.push(new ModelClass(schemaValue));
    }
  });
  const EnumCollection = app.AbstractCollection.extend({
    model: ModelClass,
  });
  return new EnumCollection(models, { sort });
};

app.AbstractCollection.createFromIntValues = (min, max) => {
  const models = [];
  _.times(max - min + 1, (n) => {
    models.push(new app.AbstractModel({ _id: min + n }));
  });
  return new app.AbstractCollection(models, { sort: app.AbstractCollection.SortOptions.ID });
};

app.AbstractCollection.createFromArray = ({ values, nameGenerator }) => {
  const models = values.map((value) => {
    return new app.AbstractModel({ _id: value, name: nameGenerator(value) });
  });
  return new app.AbstractCollection(models, { sort: app.AbstractCollection.SortOptions.ID });
};

/**
 * @description Creates a collection with enum values from the specified enum schema
 * @param {Object} tsEnum a TS enum
 * @param {(value: string | number): string} nameGenerator
 * @param {Array<string | number>} values if specified then only enums in this array will be
 * included in the collection
 * @param {Class extends AbstractModel} ModelClass
 * @param {AbstractCollection.SortOptions} sort
 * @returns {AbstractCollection}
 */
app.AbstractCollection.createFromTsEnum = ({
  tsEnum,
  nameGenerator,
  values,
  ModelClass = app.AbstractModel,
  sort = app.AbstractCollection.SortOptions.NAME,
  useEnumValueAsName = false,
  disclaimerGenerator,
}) => {
  let enumValues = allEnumValues(tsEnum).filter((value) => {
    // Pick only the values we want
    return !values || values.indexOf(value) >= 0;
  });

  if (values && sort === app.AbstractCollection.SortOptions.AS_IS) {
    enumValues = enumValues.sort((a, b) => {
      return values.indexOf(b) - values.indexOf(a);
    });
  }

  const enumModels = enumValues.map((value) => {
    const disclaimer = disclaimerGenerator?.(value);
    return new ModelClass({
      _id: value,
      name: useEnumValueAsName ? value : nameGenerator(value),
      ...(disclaimer && { disclaimer }),
    });
  });
  const EnumCollection = app.AbstractCollection.extend({
    model: ModelClass,
  });
  return new EnumCollection(enumModels, { sort });
};
